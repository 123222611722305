import axios from 'axios'
import router from '../router/index.js'
import localStorage from './localStorage.js'
import configure from './configure.js'
import { Message } from 'element-ui'

export default (options) => {
  return new Promise((resolve, reject) => {
    const request = axios.create({
      baseURL: configure.requestURL, // 设置统一的请求前缀
      timeout: 10000, // 设置统一的超时时长
    })
    // 请求拦截器
    request.interceptors.request.use(
      (config) => {
        // 设置请求头
        if (localStorage.getItem('token'))
          config.headers.token = localStorage.getItem('token')
        return config
      },
      (error) => {
        Message.error(error.message)
        reject(error.message)
      }
    )
    // 响应拦截
    request.interceptors.response.use(
      (config) => {
        if (config.data.code !== 1) {
          Message.error(config.data.msg)
          reject(config.data)
        } else {
          resolve(config.data)
        }
      },
      (error) => {
        // debugger
        if (error.response.data.code === 401) {
          if (localStorage.getItem('token')) {
            Message.error('登录过期，请重新登录。')
          }
          localStorage.removeItem('token')
          router.push('/login')
        } else {
          Message.error(error.message)
        }
        reject(error)
      }
    )
    let timestamp = new Date().getTime()
    let data = {}
    if (options.method === 'post')
      data = {
        time: timestamp,
        login_type: 0,
        ...options.data,
      }
    if (options.method === 'get')
      data = {
        params: {
          time: timestamp,
          login_type: 0,
          ...options.data,
        },
      }
    request[options.method](options.url, data)
  })
}

// export default request
//
