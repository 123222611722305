<template>
	<div class="box" v-loading="loading">
		<div class="title">
			<el-breadcrumb
				separator-class="el-icon-arrow-right"
				style="display: flex; align-items: center;"
			>
				<el-breadcrumb-item
					:to="{path: '/planCustomize/planCustomize_my'}"
					style="font-size: 30px;"
					replace
				>
					定制策划案
				</el-breadcrumb-item>
				<el-breadcrumb-item>定制策划案详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="content">
			<div
				v-html="details.content"
				style="overflow:auto;height: 100%"
			></div>
		</div>
		<div class="footer">
			<el-button
				@click="dissatisfiedShow = true"
				v-if="details.status && details.status.key == 3"
			>
				不满意
			</el-button>
			<el-popconfirm
				title="是否确认并下载策划案?"
				confirm-button-text="是"
				cancel-button-text="否"
				@confirm="confirmCustomizedCases(2)"
				v-if="details.status && details.status.key == 3"
			>
				<el-button type="primary" slot="reference">
					确认完成并下载策划案
				</el-button>
			</el-popconfirm>
			<el-button
				type="primary"
				@click="download"
				v-if="details.status && details.status.key == 4"
			>
				下载策划案
			</el-button>
		</div>
		<!-- 表单 -->
		<el-dialog
			title="不满意原因"
			:visible.sync="dissatisfiedShow"
			width="50%"
		>
			<el-form label-position="top">
				<el-form-item label="输入不满意原因:">
					<!-- 输入框3 -->
					<el-input
						type="textarea"
						v-model="reason"
						border
						maxlength="500"
						placeholder="请输入原因，不多于500字，我们将根据您的需求调整策划案！"
						autosize
						resize="none"
					></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dissatisfiedShow = false">关 闭</el-button>
				<el-button @click="confirmCustomizedCases(3)" type="primary">
					确 定
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
	name: 'plan_my',
	data() {
		return {
			loading: false,
			id: null,
			details: {},
			dissatisfiedShow: false,
			reason: '',
		}
	},
	computed: {
		...mapState(['userInfo']),
	},
	created() {
		if (this.$route.query.id) {
			this.id = this.$route.query.id
			this.getMyCustomizedCasesDetails()
		} else {
			this.$message.error('策划案不存在！')
		}
	},
	watch: {},
	methods: {
		// 获取策划案详情
		getMyCustomizedCasesDetails() {
			this.loading = true
			this.$http.plan
				.getMyCustomizedCasesDetails({
					id: this.id,
				})
				.then(res => {
					this.details = res.data
					this.loading = false
				})
		},
		// 下载
		download() {
			this.$message.success('已付款')
			this.$router.push('/planCustomize/planCustomize_my')
			let token = this.$localStorage.getItem('token')
			window.open(
				'https://bk.qsjykj.com.cn/api/cases/download?id=' +
					this.id +
					'&type=3' +
					'&token=' +
					token
			)
		},
		// 确认/不满意深度定制策划案 	用户确认状态:2=确认,3=不满意
		confirmCustomizedCases(e) {
			this.$http.plan
				.confirmCustomizedCases({
					id: this.id,
					user_confirm_status: e,
					reason: this.reason,
				})
				.then(res => {
					if (e == 2) {
						this.download()
					} else if (e == 3) {
						this.$message.success('提交成功')
					}
				})
		},
	},
}
</script>

<style type="text/css" scoped lang="scss">
.box {
	box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
	width: 100%;
	height: 100%;
	border-radius: 26px;
}
.title {
	font-size: 30px;
	color: #333;
	font-weight: 700;
	padding: 15px 70px;
	border-bottom: 1px solid #4086fc;
}
.content {
	padding: 15px 70px;
	overflow: auto;
	height: 670px;
}
.footer {
	display: flex;
	justify-content: space-around;
	.el-button {
		width: 200px;
	}
}
.again_title {
	font-size: 34px;
	color: #333;
	font-weight: 700;
	text-align: center;
}
.again_list {
	padding: 0 160px;
	font-size: 28px;
}
.again_list_li {
	margin-top: 22px;
}
</style>
