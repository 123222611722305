import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../views/login/login.vue'
import home from '../views/home/home.vue'
import index from '../views/index/index.vue'
import plan_my from '../views/plan/plan_my.vue'
import plan_add from '../views/plan/plan_add.vue'
import plan_detailsPlan from '../views/plan/plan_details-plan.vue'
import planCustomize_my from '../views/plan/planCustomize_my.vue'
import planCustomize_details from '../views/plan/planCustomize_details.vue'
import planCustomize_add from '../views/plan/planCustomize_add.vue'
import calendar from '../views/calendar/calendar.vue'
import consult_index from '../views/consult/consult_index.vue'
import consult_audio_form from '../views/consult/consult_audio_form.vue'
import consult_graphic_form from '../views/consult/consult_graphic_form.vue'
import consult_audio_list from '../views/consult/consult_audio_list.vue'
import consult_graphic_list from '../views/consult/consult_graphic_list.vue'
import mall_index from '../views/mall/mall_index.vue'
import mall_details from '../views/mall/mall_details.vue'
import mall_order from '../views/mall/mall_order.vue'
import my_index from '../views/my/my_index.vue'
import uploadPlan_index from '../views/uploadPlan/uploadPlan_index.vue'
import uploadPlan_add from '../views/uploadPlan/uploadPlan_add.vue'
import uploadPlan_details from '../views/uploadPlan/uploadPlan_details.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: '登录',
    component: login,
  },
  {
    path: '/calendar',
    name: '日历',
    component: home,
    redirect: '/calendar/calendar',
    children: [
      {
        path: 'calendar',
        component: calendar,
      },
    ],
  },
  {
    path: '/home',
    name: '首页',
    component: home,
    redirect: '/home/index',
    children: [
      {
        path: 'index',
        component: index,
      },
    ],
  },
  {
    path: '/plan',
    name: '智能策划案',
    component: home,
    redirect: '/plan/plan_my',
    children: [
      {
        path: 'plan_my',
        component: plan_my,
      },
      {
        path: 'plan_add',
        component: plan_add,
      },
      {
        path: 'plan_detailsPlan',
        component: plan_detailsPlan,
      },
    ],
  },
  {
    path: '/planCustomize',
    name: '定制策划案',
    component: home,
    redirect: '/planCustomize/planCustomize_my',
    children: [
      {
        path: 'planCustomize_my',
        component: planCustomize_my,
      },
      {
        path: 'planCustomize_details',
        component: planCustomize_details,
      },
      {
        path: 'planCustomize_add',
        component: planCustomize_add,
      },
    ],
  },
  {
    path: '/consult',
    name: '咨询顾问',
    component: home,
    redirect: '/consult/consult_index',
    children: [
      {
        path: 'consult_index',
        component: consult_index,
      },
      {
        path: 'consult_audio_form',
        component: consult_audio_form,
      },
      {
        path: 'consult_graphic_form',
        component: consult_graphic_form,
      },
      {
        path: 'consult_audio_list',
        component: consult_audio_list,
      },
      {
        path: 'consult_graphic_list',
        component: consult_graphic_list,
      },
    ],
  },
  {
    path: '/mall',
    name: '礼品采购',
    component: home,
    redirect: '/mall/mall_index',
    children: [
      {
        path: 'mall_index',
        component: mall_index,
      },
      {
        path: 'mall_details',
        component: mall_details,
      },
      {
        path: 'mall_order',
        component: mall_order,
      },
    ],
  },
  {
    path: '/my',
    name: '我的',
    component: home,
    redirect: '/my/my_index',
    children: [
      {
        path: 'my_index',
        component: my_index,
      },
    ],
  },
  {
    path: '/uploadPlan',
    name: '上传策划案',
    component: home,
    redirect: '/uploadPlan/uploadPlan_index',
    children: [
      {
        path: 'uploadPlan_index',
        component: uploadPlan_index,
      },
      {
        path: 'uploadPlan_add',
        component: uploadPlan_add,
      },
      {
        path: 'uploadPlan_details',
        component: uploadPlan_details,
      },
    ],
  },
]
// mode: 'history',
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
