<template>
	<div class="login">
		<div class="login_box">
			<div class="login_box_bg">
				<img src="../../assets/images/logo_box-bg.png" />
			</div>
			<div class="login_box_form">
				<div class="login_box_form-title">营销策划智能服务</div>
				<!-- 登录方式选择 -->
				<div class="login_box_form-tag" v-if="type === 'login'">
					<span
						:class="loginType === 'QRCode' ? 'activ' : ''"
						@click="loginType = 'QRCode'"
					>
						小程序扫码
					</span>
					<span
						:class="loginType === 'register' ? 'activ' : ''"
						@click="loginType = 'register'"
					>
						账号/手机号登录
					</span>
				</div>
				<!-- 免费注册 -->
				<div
					class="login_box_form-tag"
					v-else-if="type === 'register'"
				>
					<span class="activ">免费注册</span>
				</div>
				<div class="login_box_form-content">
					<!-- 二维码登录 -->
					<QRCode v-if="type === 'login' && loginType === 'QRCode'" />
					<!-- 账号登录 -->
					<account
						v-else-if="type === 'login' && loginType === 'register'"
					/>
					<!-- 免费注册 -->
					<register
						v-else-if="type === 'register'"
						@register="register"
					></register>
				</div>
				<div class="login_box_form-footer" v-if="type === 'login'">
					还没有账户？
					<span @click="type = 'register'">立即注册</span>
				</div>
				<div
					class="login_box_form-footer"
					v-else-if="type === 'register'"
				>
					已有账户？
					<span @click="type = 'login'">立即登录</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QRCode from '../../components/login/QRCode.vue'
import account from '../../components/login/account.vue'
import register from '../../components/register/register.vue'
import {mapState, mapActions} from 'vuex'

export default {
	name: 'login',
	components: {
		QRCode,
		account,
		register,
	},
	data() {
		return {
			type: 'login', // 登录login 注册register
			loginType: 'QRCode', //账号登录account 验证码登录QRCode
		}
	},
	created() {
		if (this.$route.query.token)
			this.$localStorage.setItem('token', this.$route.query.token)
		if (this.$localStorage.getItem('token')) this.tokenCheck()
	},
	methods: {
		...mapActions(['keepUserInfo']),
		tokenCheck() {
			this.$http.user.tokenCheck().then(res => {
				this.$message.success('登录成功')
				this.$router.replace('/home/index')
			})
		},
		// 注册完成
		register() {
			this.type = 'login'
		},
	},
}
</script>

<style scoped lang="scss">
.login {
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #333333;

	&_box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 1550px;
		height: 830px;
		background-color: #ffffff;
		border-radius: 40px;
		display: flex;
		overflow: hidden;

		> div {
			width: 50%;
			height: 100%;
		}

		&_bg {
			background: linear-gradient(180deg, #78b8fe 0%, #4382fc 100%);
			box-sizing: border-box;
			padding: 120px 91px;
			img{
				width: 100%;
				height: 100%;
			}
		}

		&_form {
			position: relative;
			&-title {
				text-align: center;
				font-size: 50px;
				margin-top: 76px;
				color: #333333;
				margin-bottom: 80px;
			}

			&-tag {
				display: flex;
				justify-content: space-around;
				font-size: 30px;
				color: #9b9b9b;
				cursor: pointer;
			}

			.activ {
				color: #333333;
				border-bottom: 2px solid #4d9bec;
			}
			&-content {
				margin-top: 40px;
			}
			&-footer {
				position: absolute;
				bottom: 20px;
				left: 50%;
				transform: translateX(-50%);
				span {
					cursor: pointer;
					color: #4d9bec;
				}
			}
		}
	}
}
</style>
