let utils = {
  //时间戳转换方法    date:时间戳数字
  formatDate(date) {
    var date = new Date(date * 1000)
    var YY = date.getFullYear()
    var MM =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var mm =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var ss =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return {
      Y: YY,
      M: MM,
      D: DD,
      h: hh,
      m: mm,
      s: ss,
    }
    // +" "+hh + mm + ss;
  },
  // 获取当前时间
  getDate(data) {
    let myDate
    if (data) {
      myDate = data
    } else {
      myDate = new Date()
    }
    let year = myDate.getFullYear()
    let month = myDate.getMonth() + 1
    let day = myDate.getDate()
    let h = myDate.getHours()
    let m = myDate.getMinutes()
    let s = myDate.getSeconds()
    return {
      YYYY: year,
      MM: month < 10 ? month : month,
      DD: day < 10 ? '0' + day : day,
      h: h < 10 ? '0' + h : h,
      m: m < 10 ? '0' + m : m,
      s: s < 10 ? '0' + s : s,
    }
  },
  // 计算时间之间相差分钟
  TimeDifference(time1, time2) {
    //判断开始时间是否大于结束日期
    // if (time1 > time2) {
    // 	utils.toast("开始时间不能大于结束时间！");
    // 	return false;
    // }
    //截取字符串，得到日期部分"2009-12-02",用split把字符串分隔成数组
    console.log(time1, time2)
    let begin1 = time1.substr(0, 10).split('-')
    let end1 = time2.substr(0, 10).split('-')
    //将拆分的数组重新组合，并实例成化新的日期对象
    let date1 = new Date(begin1[1] + -+begin1[2] + -+begin1[0])
    let date2 = new Date(end1[1] + -+end1[2] + -+end1[0])
    //得到两个日期之间的差值m，以分钟为单位
    //Math.abs(date2-date1)计算出以毫秒为单位的差值
    //Math.abs(date2-date1)/1000得到以秒为单位的差值
    //Math.abs(date2-date1)/1000/60得到以分钟为单位的差值
    let m = parseInt(Math.abs(date2 - date1) / 1000 / 60)
    //小时数和分钟数相加得到总的分钟数
    //time1.substr(11,2)截取字符串得到时间的小时数
    //parseInt(time1.substr(11,2))*60把小时数转化成为分钟
    let min1 =
      parseInt(time1.substr(11, 2)) * 60 + parseInt(time1.substr(14, 2))
    let min2 =
      parseInt(time2.substr(11, 2)) * 60 + parseInt(time2.substr(14, 2))
    //两个分钟数相减得到时间部分的差值，以分钟为单位
    let n = min2 - min1
    //将日期和时间两个部分计算出来的差值相加，即得到两个时间相减后的分钟数
    let minutes = m + n
    return minutes
  },
}

export default utils
