<template>
	<!-- 支付二维码 -->
	<el-dialog
		title="微信扫码支付"
		:visible.sync="codeShow"
		width="30%"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
	>
		<div class="qrcode"><img :src="qrcodeUrl" /></div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="cancel">取 消</el-button>
			<el-button type="primary" @click="complete">完成支付</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	name: 'qrcode',
	props: {
		codeShow: {
			type: Boolean,
			default: false,
		},
		qrcodeUrl: {
			type: String,
			default: '',
		},
	},
	methods: {
		cancel() {
			this.$emit('cancel')
			this.$emit('update:codeShow', false)
		},
		complete() {
			this.$emit('complete')
			this.$emit('update:codeShow', false)
		},
	},
}
</script>

<style scoped lang="scss">
.el-menu-item {
	float: right !important;
}
.qrcode img{
	margin: 0 auto;
}
</style>
