import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userInfo: {
		}
	},
	mutations: {
		USER_INFO(state, payload) {
			// 判断是否完善信息
			state.userInfo = payload
		},
	},
	actions: {
		keepUserInfo({
			commit
		}, data) {
			commit('USER_INFO', data)
		}
	},
	modules: {}
})
