<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/plan/plan_my' }"
          style="font-size: 30px"
          replace
        >
          智能策划
        </el-breadcrumb-item>
        <el-breadcrumb-item>智能策划案详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form>
        <el-form-item v-for="(item, index) in list" :key="index">
          <div v-html="item.content"></div>
          <div
            class="replace text-center"
            v-if="details.status && details.status.key !== 3"
            @click="toAgain(item)"
          >
            <i class="el-icon-edit-outline"></i>替换此段落
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button type="primary" @click="download"> 下载策划案 </el-button>
    </div>
    <!-- 替换段落窗口 -->
    <el-dialog
      title="替换段落"
      :visible.sync="againShow"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div style="height: 500px; overflow: auto">
        <el-form>
          <el-form-item v-for="(item, index) in againParagraph" :key="index">
            <el-radio
              class="radio"
              v-model="update_paragraph_id"
              :label="item.id"
              >{{ null }}</el-radio
            >
            <div style="padding-left: 25px" v-html="item.content"></div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="againShow = false">取 消</el-button>
        <el-button type="primary" @click="updateParagraph"> 替换 </el-button>
      </span>
    </el-dialog>
    <!-- 下单窗口 -->
    <el-dialog title="下单" :visible.sync="payDialogShow" width="30%" center>
      <el-form ref="form">
        <el-form-item label="策划案金额">
          {{ details.pay_money }}元
        </el-form-item>
        <el-form-item label="剩余积分抵扣">
          <span>{{ userInfo.score / 100 }}元</span>
          <el-switch v-model="is_score" style="float: right"></el-switch>
        </el-form-item>
        <el-form-item label="剩余余额抵扣">
          <span>{{ userInfo.money }}元</span>
          <el-switch v-model="is_money" style="float: right"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="payNewCasesOrder"> 确 定 </el-button>
      </span>
    </el-dialog>
    <!-- 支付二维码 -->
    <qrcode
      :codeShow.sync="codeShow"
      :qrcodeUrl="qrcodeUrl"
      @complete="completePay"
      @cancel="cancelPay"
    ></qrcode>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import qrcode from '../../components/qrcode.vue'
export default {
  name: 'plan_my',
  data() {
    return {
      loading: false,
      id: null,
      details: new Object(),
      list: new Array(),
      againShow: false,
      againParagraph: new Array(),
      paragraph_id: null,
      update_paragraph_id: null, //替换段落id
      payDialogShow: false,
      codeShow: false,
      qrcodeUrl: null,
      is_score: false,
      is_money: false,
    }
  },
  components: {
    qrcode,
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getParagraphList()
      this.getMyCasesDetails()
    } else {
      this.$message.error('策划案不存在！')
    }
  },
  watch: {},
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    // 获取策划案详情
    getMyCasesDetails() {
      this.loading = true
      this.$http.plan
        .getMyCasesDetails({
          id: this.id,
        })
        .then((res) => {
          this.details = res.data
          this.loading = false
        })
    },
    // 获取段落列表
    getParagraphList() {
      this.$http.plan
        .getParagraphList({
          id: this.id,
        })
        .then((res) => {
          this.list = res.data
        })
    },
    // 获取替换段落列表
    toAgain(e) {
      console.log(e)
      this.paragraph_id = e.id
      this.$http.plan
        .getCaseParagraphList({
          order_id: this.id,
          paragraph_id: this.paragraph_id,
        })
        .then((res) => {
          this.againParagraph = res.data
          this.againShow = true
        })
    },
    // 替换段落
    updateParagraph() {
      this.$http.plan
        .updateParagraph({
          order_id: this.id,
          paragraph_id: this.paragraph_id,
          update_paragraph_id: this.update_paragraph_id,
        })
        .then((res) => {
          this.$message.success('替换成功')
          this.getMyCasesDetails()
          this.getParagraphList()
          this.againShow = false
        })
    },
    // 支付
    payNewCasesOrder() {
      this.$http.plan
        .payNewCasesOrder({
          method: 'scan',
          is_score: this.is_score,
          is_money: this.is_money,
          id: this.id,
        })
        .then((res) => {
          if (res.data.is_pay) {
            this.codeShow = true
            this.qrcodeUrl = res.data.pay.qrcode
          } else {
            this.$message.success('余额或积分已抵扣完成无需支付。')
          }
          this.payDialogShow = false
          this.getuserinfo()
        })
    },
    // 下载
    download() {
      if (this.details.status.key == 1 || this.details.status.key == 2) {
        this.payDialogShow = true
      } else if (this.details.status.key == 3) {
        this.$message.success('已付款')
        this.$router.push('/plan/plan_my')
        let token = this.$localStorage.getItem('token')
        window.open(
          `https://bk.qsjykj.com.cn/api/cases/download?id=${this.id}&type=${this.details.type}&token=${token}`
        )
      }
    },
    // 完成支付
    completePay() {
      this.$router.push('/plan/plan_my')
    },
    // 取消支付
    cancelPay() {
      this.$message.error('取消支付！')
      this.$router.push('/plan/plan_my')
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 15px 70px;
  overflow: auto;
  height: 670px;
}
.footer {
  display: flex;
  justify-content: space-around;
  .el-button {
    width: 200px;
  }
}
.radio {
  position: absolute;
  left: 0;
  top: 13px;
}
.replace {
  color: #6dadfd;
  cursor: pointer;
}
</style>
