<template>
	<el-dialog
		title="绑定手机"
		:visible.sync="show"
		width="30%"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
		:show-close="false"
	>
		<el-form :model="mobileForm" :rules="rules" ref="mobileForm">
			<el-form-item
				label="手机号:"
				prop="mobile"
				:label-width="formLabelWidth"
			>
				<el-input
					v-model="mobileForm.mobile"
					autocomplete="off"
				></el-input>
			</el-form-item>
			<el-form-item
				label="验证码:"
				prop="captcha"
				:label-width="formLabelWidth"
			>
				<el-input
					v-model="mobileForm.captcha"
					autocomplete="off"
				></el-input>
				<el-button
					type="primary"
					class="getVerificationBut"
					:loading="captchaLoading"
					size="mini"
					round
					@click="getCaptcha"
				>
					获取验证码
				</el-button>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button
				type="primary"
				:loading="submitLoading"
				@click="submit"
			>
				提交
			</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	name: 'navMenu',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mobileForm: {
				mobile: '',
				captcha: '',
			},
			rules: {
				mobile: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
				],
				captcha: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				],
			},
			formLabelWidth: '70px',
			submitLoading: false,
			captchaLoading: false,
		}
	},
	methods: {
		submit() {
			this.$refs['mobileForm'].validate(valid => {
				if (!valid) return false
				this.submitLoading = true
				this.$http.user
					.changemobile(this.mobileForm)
					.then(res => {
						this.$message.success('绑定成功。')
						this.$emit('submit')
						this.submitLoading = false
					})
					.catch(res => {
						this.submitLoading = false
					})
			})
		},
		// 获取验证码
		getCaptcha() {
			if (this.mobileForm.mobile) {
				this.captchaLoading = true
				this.$http.user
					.mobileSend({
						mobile: this.mobileForm.mobile,
						event: 'changemobile',
					})
					.then(res => {
						this.$message.success('验证码发送成功。')
						this.captchaLoading = false
					})
					.catch(res => {
						this.captchaLoading = false
					})
			} else {
				this.$message.error('请输入正确的手机号。')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.el-form {
	padding: 0 30px;
}
.getVerificationBut {
	position: absolute !important;
	right: 10px !important;
	top: 50% !important;
	transform: translateY(-50%) !important;
}
</style>
