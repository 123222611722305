<template>
  <div class="box" v-loading="loading">
    <div class="title">策划案上传</div>
    <div class="content">
      <router-link to="/uploadPlan/uploadPlan_add">
        <el-button type="primary" icon="el-icon-plus" size="small">
          上传策划案
        </el-button>
      </router-link>
      <div class="filter">
        <el-select v-model="status" placeholder="全部状态" size="small"
          ><el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option
        ></el-select>
        <el-date-picker
          v-model="time"
          type="daterange"
          align="right"
          size="small"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <el-input
          style="width: 280px"
          v-model="search"
          placeholder="请输入策划案名称"
          size="small"
        ></el-input>
        <el-button type="primary" size="small" @click="getSearch"
          >查询</el-button
        >
        <el-button size="small" @click="reset">重置</el-button>
      </div>
      <div class="table">
        <el-table
          stripe
          :data="tableData.list"
          border
          size="mini"
          max-height="590"
          height="590"
        >
          <el-table-column
            prop="id"
            label="序号"
            width="60"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="策划案名称"
            width="500"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="case_image"
            label="策划案展示图"
            :resizable="false"
          >
            <template slot-scope="scope">
              <el-image
                style="height: 32px"
                :src="scope.row.case_image"
                :preview-src-list="[scope.row.case_image]"
                fit="contain"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            label="上传时间"
            width="140"
            :resizable="false"
          ></el-table-column>
          <el-table-column label="表单内容" :resizable="false" width="70">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="formDetalis(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="buy_number"
            label="使用次数"
            width="70"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="profit"
            label="收益"
            width="70"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="verify_status.value"
            label="状态"
            width="70"
            :resizable="false"
          ></el-table-column>
          <el-table-column label="编辑/操作" :resizable="false">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="details(scope.row)">
                查看编辑策划案
              </el-button>
              <el-button
                v-if="scope.row.verify_status === 2"
                type="text"
                size="small"
                @click="applyDownOnline(scope.row)"
                >申请下线</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="text-align: right"
          background
          layout="prev, pager, next"
          :total="tableData.total_count"
          :page-size="pageSize"
          :current-page="current_page"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 表单 -->
    <el-dialog title="表单内容" :visible.sync="formShow" width="50%">
      <el-form
        label-position="top"
        disabled
        style="overflow: auto; height: 500px"
      >
        <el-form-item
          :label="item.title"
          v-for="(item, i) in formData"
          :key="i"
        >
          <!-- 输入框3 -->
          <el-input v-model="item.value" v-if="item.field_type == 3"></el-input>
          <!-- 单选1 -->
          <el-radio-group v-model="item.value" v-if="item.field_type == 1">
            <el-radio
              :label="radioItem.key"
              v-for="(radioItem, radioIndex) in item.select_option"
              :key="radioIndex"
              >{{ radioItem.value }}</el-radio
            >
          </el-radio-group>
          <!-- 多选2 -->
          <div v-if="item.field_type == 2">
            <el-checkbox
              :value="true"
              v-for="(checkboxItem, checkboxIndex) in item.value"
              :key="checkboxIndex"
              :label="checkboxItem.value"
            ></el-checkbox>
          </div>
          <!-- 时间选择4 -->
          <el-input v-model="item.value" v-if="item.field_type == 4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer"
        ><el-button @click="formShow = false" type="primary"
          >关 闭</el-button
        ></span
      >
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '待审核',
        },
        {
          value: 2,
          label: '已上线',
        },
        {
          value: 3,
          label: '已下线',
        },
        {
          value: 4,
          label: '审核驳回',
        },
      ],
      status: '',
      current_page: 1,
      pageSize: 15,
      time: new Array(),
      search: null,
      tableData: {
        list: new Array(),
      },
      formShow: false,
      formData: new Array(),
    }
  },
  components: {},
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    this.getcaseslist()
  },
  watch: {},
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    getcaseslist() {
      this.loading = true
      this.$http.plan
        .getcaseslist({
          verify_status: this.status,
          current_page: this.current_page,
          page_size: this.pageSize,
          start_time: this.time[0],
          end_time: this.time[1],
          search: this.search,
        })
        .then((res) => {
          console.log(res)
          this.tableData = res.data
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 分页
    currentChange(e) {
      this.current_page = e
      this.getcaseslist()
    },
    // 查询
    getSearch() {
      this.current_page = 1
      this.getcaseslist()
    },
    // 重置
    reset() {
      this.status = ''
      this.current_page = 1
      this.time = new Array()
      this.search = null
      this.getcaseslist()
    },
    //查看详情
    details(e) {
      this.$router.push({
        path: '/uploadPlan/uploadPlan_details',
        query: {
          id: e.id,
        },
      })
    },
    // 查看表单
    formDetalis(e) {
      this.$http.plan
        .getCaseDetails({
          id: e.id,
        })
        .then((res) => {
          this.formData = res.data.from_list
          this.formShow = true
        })
    },
    //申请下线
    applyDownOnline(e) {
      this.$confirm('此操作将下线该策划案, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http.plan
            .applyDownOnline({
              id: e.id,
            })
            .then((res) => {
              this.$message.success(`下线成功`)
            })
        })
        .catch(() => {
          this.$message.info('已取消下线')
        })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 15px 70px;
}
.filter {
  display: flex;
  margin-top: 20px;
  > * {
    margin-right: 20px;
  }
}
.table {
  margin-top: 20px;
}
</style>
