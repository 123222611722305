<template>
  <div class="box">
    <div class="title">营销日历</div>
    <div class="content">
      <div class="content_l">
        <el-calendar v-model="value">
          <template slot="dateCell" slot-scope="{ date, data }">
            <div style="height: 100%">
              <div class="day">{{ $utils.getDate(date).DD }}</div>
              <div
                class="festival"
                v-for="item in activDate.list"
                :key="item.calendar_time"
                v-if="item.calendar_time === data.day"
              >
                <span class="round"></span>
                {{ item.events }}
              </div>
            </div>
          </template>
        </el-calendar>
        <div class="service">
          <img
            v-for="item in serviceList[0].list"
            :key="item.id"
            :src="item.url"
            @click="details(item)"
          />
        </div>
      </div>
      <div class="content_r" v-if="activDay.id">
        <div class="content_r_title">
          <div>{{ activDay.events }}</div>
          <div>
            <div>{{ activDay.calendar_time }}</div>
            <!-- <div>{{activDay}}</div> -->
          </div>
        </div>
        <img :src="activDay.calendar_image" />
        <div class="content_r_text">{{ activDay.remarks }}</div>
      </div>
      <div class="content_r" v-else>
        <el-empty description="本日无事件"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'plan_my',
  data() {
    return {
      value: new Date(),
      activDate: {},
      activDay: {},
      year: '',
      month: '',
      day: '',
      serviceList: new Array(),
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },

  created() {
    let myDate = this.$utils.getDate()
    this.year = myDate.YYYY
    this.month = myDate.MM
    this.day = myDate.DD
    this.getIndexList()
  },
  watch: {
    value(e) {
      let myDate = this.$utils.getDate(e)
      this.year = myDate.YYYY
      this.month = myDate.MM
      this.day = myDate.DD
      this.activ()
    },
    month() {
      this.getcalendarlist()
    },
  },
  methods: {
    activ() {
      this.$http.calendar
        .getcalendardetails({
          calendar_time: this.year + '-' + this.month + '-' + this.day,
        })
        .then((res) => {
          console.log(res)
          this.activDay = res.data
        })
    },
    getcalendarlist() {
      this.$http.calendar
        .getcalendarlist({
          calendar_time: this.year + '-' + this.month,
        })
        .then((res) => {
          this.activDate = res.data
        })
    },
    getIndexList() {
      this.$http.common
        .getIndexList({
          app_model: 10,
        })
        .then((res) => {
          console.log(res)
          this.serviceList = res.data
        })
    },
    details(e) {
      // 类别:1=商品,2=链接,3=策划案,4=顾问
      let type = e.type
      if (type == 1) {
        this.$router.push({
          path: '/mall/mall_index',
          query: {
            activity_id: e.id,
          },
        })
      } else if (type == 2) {
      } else if (type == 3) {
      } else if (type == 4) {
        this.$router.push({
          path: '/consult/consult_index',
          query: {
            activity_id: e.id,
          },
        })
      }
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 15px 40px;
  display: flex;
  height: 730px;
  overflow: auto;
  justify-content: space-between;
}
.el-calendar {
  width: 1027px;
  overflow: auto;
}

.service {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  img {
    width: 506px;
    height: 204px;
    margin-bottom: 20px;
  }
}
.el-calendar-table .el-calendar-day {
  height: 70px;
}
.content_r {
  height: max-content;
  min-height: 600px;
  width: 480px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.09);
  padding: 20px;
  position: relative;
  .el-empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  img {
    height: auto;
  }
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div:nth-child(1) {
      font-size: 30px;
      font-weight: 700;
      color: #4a4a4a;
    }
    > div:nth-child(2) div:nth-child(1) {
      font-size: 26px;
      color: #4a4a4a;
    }
    > div:nth-child(2) div:nth-child(2) {
      font-size: 20px;
      color: #4a4a4a;
      text-align: right;
    }
  }
  &_text {
    font-size: 22px;
    color: #4a4a4a;
    word-wrap: break-word;
    margin-top: 20px;
  }
}
.day {
  font-size: 24px;
  font-weight: 700;
}
.festival:hover {
  color: #d81e06;
  .round {
    background-color: #d81e06;
  }
}
.round {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #417505;
}
</style>
<style scoped>
.content_l >>> .el-calendar__title {
  font-size: 24px;
  font-weight: 700;
}
</style>
