<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/consult' }"
          style="font-size: 30px"
          replace
        >
          在线咨询
        </el-breadcrumb-item>
        <el-breadcrumb-item>音频咨询</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form label-position="top">
        <el-form-item
          required
          :label="item.title"
          v-for="item in formData"
          :key="item.id"
        >
          <!-- 输入框3 -->
          <el-input
            v-model="item.value"
            style="width: 220px"
            v-if="item.field_type === 3"
            :placeholder="`请输入${item.title}`"
            @input="$forceUpdate()"
          ></el-input>
          <!-- 单选框 -->
          <el-radio-group v-model="item.value" v-if="item.field_type === 1">
            <el-radio
              :label="radioItem.key"
              v-for="(radioItem, radioIndex) in item.select_option"
              :key="radioIndex"
              @change="$forceUpdate()"
            >
              {{ radioItem.value }}
            </el-radio>
          </el-radio-group>
          <!-- 多选 -->
          <div v-if="item.field_type == 2">
            <el-checkbox
              :label="checkboxItem.value"
              v-for="(checkboxItem, checkboxIndex) in item.select_option"
              :key="checkboxIndex"
              v-model="checkboxItem.checked"
              @change="$forceUpdate()"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="预约时间" required>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item
          :label="`积分抵扣(账户剩余积分可抵扣${userInfo.score / 100}元)`"
        >
          <el-switch v-model="is_score"></el-switch>
        </el-form-item>
        <el-form-item
          :label="`余额抵扣:(账户剩余余额可抵扣${userInfo.money}元)`"
        >
          <el-switch v-model="is_money"></el-switch>
        </el-form-item>
        <el-form-item label="应付金额" v-if="calculateTime">
          ￥{{ calculateTime > 0 ? calculateTime : 0 }}
        </el-form-item>
        <el-form-item label="应付金额" v-else style="color: #ccc">
          选择预约时间后显示
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button @click="getFromField">重置</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
    <!-- 支付二维码 -->
    <qrcode
      :codeShow.sync="codeShow"
      :qrcodeUrl="qrcodeUrl"
      @complete="completePay"
      @cancel="cancelPay"
    ></qrcode>
    <el-dialog
      title="提示"
      :visible.sync="show"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div>预约已提交，等待顾问确认！ 可在音频历史咨询中查看详情</div>
      <span slot="footer" class="dialog-footer">
        <el-button>音频历史咨询</el-button>
        <el-button type="primary" @click="$router.push('/consult')">
          继续咨询
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import qrcode from '../../components/qrcode.vue'
export default {
  name: 'consult_form',
  data() {
    return {
      id: null,
      formData: new Array(),
      loading: false,
      is_score: false,
      is_money: false,
      time: null,
      show: false,
      codeShow: false,
      qrcodeUrl: null,
      video_consult_price: null,
      price: null,
      order: null,
      minute: null,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    calculateTime() {
      if (this.time) {
        let data
        let start_time = this.$utils.getDate(this.time[0])
        let end_time = this.$utils.getDate(this.time[1])
        let start = `${start_time.YYYY}-${start_time.MM}-${start_time.DD} ${start_time.h}:${start_time.m}`
        let end = `${end_time.YYYY}-${end_time.MM}-${end_time.DD} ${end_time.h}:${end_time.m}`
        let minute = this.$utils.TimeDifference(start, end)
        console.log(minute)
        data = parseFloat(this.video_consult_price).toFixed(2) * minute
        let price
        let score = parseFloat(this.userInfo.score).toFixed(2) / 100
        let money = parseFloat(this.userInfo.money).toFixed(2)
        if (this.is_score && this.is_money) {
          price = parseFloat(data - score - money).toFixed(2)
        } else if (!this.is_score && this.is_money) {
          price = parseFloat(data - money).toFixed(2)
        } else if (this.is_score && !this.is_money) {
          price = parseFloat(data - score).toFixed(2)
        } else {
          price = parseFloat(data).toFixed(2)
        }
        return price
      } else {
        return false
      }
    },
  },
  components: {
    qrcode,
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.video_consult_price = this.$route.query.video_consult_price
      this.price = this.$route.query.video_consult_price
      this.getFromField()
    }
  },
  watch: {},
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    // 获取表单
    getFromField() {
      this.loading = true
      this.$http.common
        .getFromField({
          type: 4,
        })
        .then((res) => {
          this.formData = res.data
          this.formData.forEach((item, i) => {
            item.value = ''
            item.field_id = item.id
            if (item.select_option) {
              item.select_option = JSON.parse(item.select_option)
              if (item.field_type == 2) {
                item.select_option.forEach((resItem, index) => {
                  item.select_option[index].checked = false
                })
              }
            }
          })
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true
      let data = new Array()
      this.formData.forEach((item, i) => {
        if (item.select_option && item.field_type == 2) {
          item.value = new Array()
          item.select_option.forEach((resItem) => {
            if (resItem.checked) {
              this.formData[i].value.push({
                value: resItem.value,
                key: resItem.key,
              })
            }
          })
        }
        data.push({
          field_id: item.field_id,
          value: item.value,
        })
      })
      let start_time, end_time
      try {
        start_time = this.$utils.getDate(this.time[0])
        end_time = this.$utils.getDate(this.time[1])
      } catch (error) {
        this.loading = false
        this.$message.error('请选择时间')
        return
      }

      // 音频下单
      this.$http.consult
        .submitVideoOrder({
          id: this.id,
          is_score: this.is_score ? 1 : 0, //number	是	是否使用积分:1=是,0=否
          is_money: this.is_money ? 1 : 0, //number	是	是否使用余额:1=是,0=否
          data: [...data],
          from_id: this.formData[0].from_id,
          start_time: `${start_time.YYYY}-${start_time.MM}-${start_time.DD} ${start_time.h}:${start_time.m}`,
          end_time: `${end_time.YYYY}-${end_time.MM}-${end_time.DD} ${end_time.h}:${end_time.m}`,
          method: 'scan',
        })
        .then((res) => {
          this.loading = false
          if (res.data.is_pay) {
            this.order = res.data.order
            this.qrcodeUrl = res.data.pay.qrcode
            this.codeShow = true
          } else {
            this.$message.success('积分/余额抵扣成功，无需支付。')
          }
        })
        .catch((res) => {
          this.loading = false
        })
      this.getuserinfo()
    },
    // 取消支付
    cancelPay() {
      this.$message.error('取消支付！')
      this.$router.go(-1)
    },
    // 完成支付
    completePay() {
      this.$http.consult
        .getVideoOrderDetails({
          id: this.order.id,
        })
        .then((res) => {
          if (res.data.video_order.status.key === 1) {
            this.$message.error('未支付！')
            this.codeShow = true
          } else {
            this.$message.success('支付成功')
            this.show = true
          }
        })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 15px 340px;
  overflow: auto;
  height: 670px;
}
.but {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #4086fc;
}
</style>
