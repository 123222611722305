<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/plan/plan_my' }"
          style="font-size: 30px"
          replace
        >
          智能策划
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增策划案</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form label-position="top">
        <el-form-item
          required
          :label="item.title"
          v-for="item in formData"
          :key="item.id"
        >
          <!-- 输入框3 -->
          <el-input
            v-model="item.value"
            v-if="item.field_type === 3"
            :placeholder="`请输入${item.title}`"
            @input="$forceUpdate()"
          ></el-input>
          <!-- 单选框 -->
          <el-radio-group
            v-if="item.field_type === 1"
            v-model="item.value"
            @change="$forceUpdate()"
          >
            <el-radio
              :label="radioItem.key"
              v-for="(radioItem, radioIndex) in item.select_option"
              :key="radioIndex"
            >
              {{ radioItem.value }}
            </el-radio>
          </el-radio-group>
          <!-- 多选 -->
          <div v-if="item.field_type == 2">
            <el-checkbox
              :label="checkboxItem.value"
              v-for="(checkboxItem, checkboxIndex) in item.select_option"
              :key="checkboxIndex"
              v-model="checkboxItem.checked"
              @change="$forceUpdate()"
            ></el-checkbox>
          </div>

          <!-- 时间选择 -->
          <el-date-picker
            v-if="item.field_type === 4"
            v-model="item.value"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @blur="$forceUpdate()"
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button @click="getFromField">重置</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'plan_my',
  data() {
    return {
      formData: [],
      loading: false,
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    this.getFromField()
  },
  watch: {},
  methods: {
    getFromField() {
      this.loading = true
      this.$http.common
        .getFromField({
          type: 1,
        })
        .then((res) => {
          this.formData = res.data
          this.formData.forEach((item) => {
            item.value = null
            item.field_id = item.id
            if (item.select_option) {
              item.select_option = JSON.parse(item.select_option)
              if (item.field_type == 2) {
                item.select_option.forEach((resItem, index) => {
                  item.select_option[index].checked = false
                })
              }
            }
          })
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true
      let data = {
        from_id: this.formData[0].from_id,
        data: new Array(),
      }
      this.formData.forEach((item, i) => {
        if (item.select_option && item.field_type == 2) {
          item.value = new Array()
          item.select_option.forEach((resItem) => {
            if (resItem.checked) {
              this.formData[i].value.push({
                value: resItem.value,
                key: resItem.key,
              })
            }
          })
        }
        data.data.push({
          field_id: item.field_id,
          value: item.value,
        })
      })
      this.$http.plan
        .submitNewCases(data)
        .then((res) => {
          this.$message.success('提交成功')
          this.loading = false
          this.$router.push({
            path: '/plan/plan_detailsPlan',
            query: { id: res.data.order.id },
          })
        })
        .catch((res) => {
          this.loading = false
        })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 15px 70px;
  overflow: auto;
  height: 670px;
}
.but {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #4086fc;
}
</style>
