<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/mall' }"
          style="font-size: 30px"
          replace
        >
          礼品采购
        </el-breadcrumb-item>
        <el-breadcrumb-item>礼品详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="hader">
        <div class="hader_l">
          <div class="hader_l_img">
            <el-image
              style="width: 100%; height: 100%"
              :src="imgSrc"
              fit="cover"
            ></el-image>
          </div>
          <div class="hader_l_imgList">
            <el-carousel
              type="card"
              height="100px"
              :autoplay="false"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="(item, i) in details.main_image_big"
                :key="i"
                @change="carouselChange"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item"
                  fit="contain"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="hader_r">
          <div class="hader_r_title">
            {{ details.title }}
          </div>
          <div class="hader_r_price">
            <span>价格</span>
            <span>￥{{ details.price }}</span>
            <span>￥{{ details.original_price }}</span>
          </div>
          <div class="hader_r_delivery">配送方式：{{ deliveryType }}</div>
          <div class="hader_r_sales">库存：{{ details.stock }}</div>
          <div class="hader_r_quantity">
            数量：<el-input-number
              v-model="form.number"
              :min="1"
              :max="details.stock"
              label="描述文字"
            ></el-input-number>
          </div>
          <div class="hader_r_buy text-center">
            <el-button type="warning" @click="buyShow = true"
              >立即购买</el-button
            >
          </div>
        </div>
      </div>
      <div class="richText">
        <div class="richText_title">商品详情</div>
        <div
          class="richText_text"
          v-html="details.intro"
          v-if="typeof details.intro === 'string'"
        ></div>
        <div class="richText_text text-center" v-else>
          <el-image
            :src="item"
            v-for="(item, i) in details.intro"
            :key="i"
          ></el-image>
        </div>
      </div>
      <div class="love">
        <div class="love_title">猜你喜欢</div>
        <div class="love_list">
          <div
            class="love_list-li"
            v-for="item in loveList"
            :key="item.id"
            @click="loveDetails(item)"
          >
            <div class="love_list-li-img">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.main_image"
                fit="contain"
              ></el-image>
            </div>
            <div class="love_list-li-title">{{ item.title }}</div>
            <div class="love_list-li-price">￥{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 确认订单、 -->
    <el-dialog
      class="buyDialog"
      title="确认订单"
      :visible.sync="buyShow"
      width="80%"
    >
      <div class="order">
        <div class="order_hader">
          <div class="order_hader_img">
            <el-image
              style="width: 100%; height: 100%"
              :src="details.main_image"
              fit="contain"
            ></el-image>
          </div>
          <div>
            <div class="order_hader_title">
              {{ details.title }}
            </div>
            <div class="order_hader_price">￥{{ details.price }}</div>
            <div class="order_hader_quantity">数量：x{{ form.number }}</div>
            <div class="order_hader_text">
              <span>共 {{ form.number }} 件商品</span>
              <span>运费：{{ freight }}</span>
              <span
                >合计：{{
                  form.number * details.price + parseInt(this.details.postage)
                }}元</span
              >
            </div>
          </div>
        </div>
        <div class="order_address">
          <el-form ref="form" :model="form" label-width="8em">
            <el-form-item label="寄送至:">
              <el-select
                v-model="form.address_id"
                placeholder="请选择地址"
                style="width: 838px"
              >
                <el-option
                  v-for="item in addressList"
                  :key="item.id"
                  :label="`姓名：${item.name}；手机号：${item.mobile}；地址：${item.mailing_address} ${item.address}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="setAddress" @click="addAddressShow = true"
                >添加新地址</span
              >
            </el-form-item>
            <el-form-item label="抵扣:">
              <el-checkbox v-model="form.is_money"
                >账户余额可抵扣：{{ userInfo.money }}元</el-checkbox
              >
              <el-checkbox v-model="form.is_score"
                >积分{{ userInfo.score }}可抵扣：{{
                  (userInfo.score / 100).toFixed(2)
                }}元</el-checkbox
              >
            </el-form-item>
            <el-form-item label="实付款:"> ￥{{ calculate }} </el-form-item>
            <el-form-item label="付款方式:">
              <el-radio value="1" label="1">微信支付</el-radio>
            </el-form-item>
            <el-form-item class="text-center">
              <el-button type="warning" @click="submit" :loading="loadingBut">
                立即购买
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 支付二维码 -->
    <qrcode
      :codeShow.sync="codeShow"
      :qrcodeUrl="qrcodeUrl"
      @complete="completePay"
      @cancel="cancelPay"
    ></qrcode>
    <!-- 添加新地址 -->
    <el-dialog title="添加地址" :visible.sync="addAddressShow" width="30%">
      <el-form
        ref="form"
        :model="address"
        label-width="8em"
        prop="name"
        :rules="addressRules"
      >
        <el-form-item label="姓名:" prop="name">
          <el-input v-model="address.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile">
          <el-input
            v-model.number="address.mobile"
            placeholder="请输入手机号"
            maxLength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址:" prop="mailing_address">
          <el-cascader
            v-model="address.mailing_address"
            :options="addressData"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="街道:" prop="street">
          <el-select
            v-model="address.street"
            prop="street"
            placeholder="请选择"
          >
            <el-option
              v-for="item in streetList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址:" prop="address">
          <el-input
            type="textarea"
            v-model="address.address"
            resize="none"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否默认:">
          <el-switch
            v-model="address.is_select"
            active-color="#e55623"
          ></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="addAddressShow = false">取 消</el-button>
        <el-button type="warning" @click="addAddress">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import qrcode from '../../components/qrcode.vue'

export default {
  data() {
    return {
      id: null,
      loading: false,
      imgSrc: '',
      details: new Object(),
      loveList: new Array(),
      buyShow: false,
      addressList: new Array(),
      form: {
        address_id: '',
        goods_id: '',
        is_score: false,
        is_money: false,
        number: 1,
      },
      codeShow: false,
      qrcodeUrl: null,
      order: new Object(),
      addAddressShow: false,
      addressData: new Array(),
      streetList: new Array(),
      address: {
        name: '', //string	是	收货人姓名
        mobile: '', //	string	是	收货手机号码
        mailing_address: '', //	string	是	邮寄地址空格分隔（湖南省 长沙市 岳麓区 洋湖街道）
        street: '', //街道
        address: '', //string	是	详细地址（柏宁地王大厦南栋9012室）
        is_select: false, //string	是	是否默认:0=否,1=是
      },
      addressRules: {
        name: [{ required: true, message: '请输入名字', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { type: 'number', message: '请输入数字' },
        ],
        mailing_address: [
          { required: true, message: '请选择地址', trigger: 'blur' },
        ],
        street: [{ required: true, message: '请选择街道', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
      },
      loadingBut: false,
    }
  },
  computed: {
    // 使用模块中的状态量时需要解构模块名，而不是状态量
    ...mapState(['userInfo']),
    // 计算抵扣价格
    calculate() {
      if (this.form.is_score && !this.form.is_money) {
        return (
          this.form.number * this.details.price +
          parseInt(this.details.postage) -
          this.userInfo.score / 100
        )
      } else if (this.form.is_money && !this.form.is_score) {
        return (
          this.form.number * this.details.price +
          parseInt(this.details.postage) -
          this.userInfo.money
        )
      } else if (this.form.is_money && this.form.is_score) {
        return (
          this.form.number * this.details.price +
          parseInt(this.details.postage) -
          this.userInfo.score / 100 -
          this.userInfo.money
        )
      } else {
        return (
          this.form.number * this.details.price + parseInt(this.details.postage)
        )
      }
    },
    // 配送方式
    deliveryType() {
      if (this.details.mail_type === 0) {
        return '不可邮寄'
      } else if (this.details.mail_type === 1) {
        return '免邮费'
      } else if (this.details.mail_type === 2) {
        return '邮寄'
      }
    },
    // 运费
    freight() {
      if (this.addressList.length) {
        return this.details.postage ? `${this.details.postage}元` : '免邮费'
      } else {
        return '请先选择地址'
      }
    },
  },
  components: {
    qrcode,
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.form.goods_id = this.$route.query.id
      this.getUserAddressList()
      this.getLikeGoodsLost()
      this.getAddrList()
    } else {
      this.$message.error('商品不存在！')
    }
  },
  watch: {
    'form.number'() {
      this.getGoodsDetails()
    },
    'form.address_id'() {
      this.getGoodsDetails()
    },
    'address.mailing_address'(e) {
      this.getStreetList(e)
    },
  },
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    // 切换图片
    carouselChange(e) {
      console.log(e)
    },
    // 获取详情
    getGoodsDetails() {
      if (Object.values(this.details).length == 0) this.loading = true
      this.$http.mall
        .getGoodsDetails({
          id: this.id,
          // address_id: this.form.address_id,
          number: this.form.number,
        })
        .then((res) => {
          this.loading = false
          this.details = res.data
          this.details.main_image_big = eval(`(${res.data.main_image_big})`)
          this.imgSrc = this.details.main_image_big[0]
          if (res.data.intro.indexOf('style') !== -1) {
            this.details.intro = res.data.intro
          } else {
            this.details.intro = JSON.parse(res.data.intro)
          }
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 猜你喜欢点击事件
    loveDetails(e) {
      this.$router.replace({
        query: {
          id: e.id,
        },
      })
      /**  刷新当前界面 */
      this.$router.go(0)
    },
    // 获取猜你喜欢
    getLikeGoodsLost() {
      this.$http.mall.getLikeGoodsLost().then((res) => {
        console.log(res)
        this.loveList = res.data
      })
    },
    //获取收货地址列表
    getUserAddressList() {
      this.$http.mall.getUserAddressList().then((res) => {
        this.addressList = res.data
        this.addressList.forEach((item) => {
          if (item.is_select) {
            this.form.address_id = item.id
          }
        })
      })
    },
    // 立即购买
    submit() {
      this.loadingBut = true
      let data = this.form
      data.is_score = data.is_score ? 1 : 0
      data.is_money = data.is_money ? 1 : 0
      data.method = 'scan'
      this.$http.mall.submitOrder(data).then((res) => {
        if (res.data.is_pay) {
          this.order = res.data.order
          this.qrcodeUrl = res.data.pay.qrcode
          this.codeShow = true
        } else {
          this.$message.success('积分/余额抵扣成功，无需支付。')
          this.buyShow = false
          this.open()
        }
        this.loadingBut = false
        this.getuserinfo()
      })
    },
    // 取消支付
    cancelPay() {
      this.$message.error('取消支付！')
    },
    // 完成支付
    completePay() {
      this.$http.mall
        .getOrderDetails({
          id: this.order.id,
        })
        .then((res) => {
          if (res.data.status.key === 0) {
            this.$message.error('未支付！')
            this.codeShow = true
            this.buyShow = false
          } else {
            this.$message.success('支付成功')
            this.open()
          }
        })
    },
    open() {
      this.$confirm('下单成功，可在我的礼品订单中查看详情！', '提示', {
        confirmButtonText: '继续采购礼品',
        cancelButtonText: '查看礼品订单',
      })
        .then(() => {
          this.buyShow = false
        })
        .catch(() => {
          this.$router.push({
            path: '/mall/mall_order',
          })
        })
    },
    // 获取地址库
    getAddrList() {
      this.$http.mall.getAddrList().then((res) => {
        console.log(res)
        this.addressData = res.data
      })
    },
    // 获取街道列表
    getStreetList(e) {
      this.$http.mall
        .getStreetList({
          area_code: e[2],
        })
        .then((res) => {
          this.streetList = res.data
        })
    },
    // 添加收获地址
    addAddress() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let mailing_address
          if (this.address.mailing_address.length) {
            this.addressData.forEach((item) => {
              if (this.address.mailing_address[0].includes(item.value)) {
                item.children.forEach((item2) => {
                  if (this.address.mailing_address[1].includes(item2.value)) {
                    item2.children.forEach((item3) => {
                      if (
                        this.address.mailing_address[2].includes(item3.value)
                      ) {
                        mailing_address = `${item.label} ${item2.label} ${item3.label}`
                        console.log(mailing_address)
                      }
                    })
                  }
                })
              }
            })
          }
          this.$http.mall
            .addAddress({
              name: this.address.name,
              mobile: this.address.mobile,
              mailing_address: `${mailing_address} ${this.address.street}`,
              address: this.address.address,
              is_select: this.address.is_select ? 1 : 0,
            })
            .then((res) => {
              this.$message.success('添加成功！')
              this.getUserAddressList()
              this.addAddressShow = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #fd8927;
}
.content {
  position: relative;
  padding: 50px 200px 10px;
  height: 730px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  .hader {
    display: flex;
    &_l {
      margin-right: 85px;
      &_img {
        width: 440px;
        height: 440px;
        margin-bottom: 20px;
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
      &_imgList {
        width: 440px;
        height: 100px;
      }
    }
    &_r {
      &_title {
        font-size: 34px;
        color: #333333;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &_price {
        height: 80px;
        line-height: 80px;
        background: linear-gradient(360deg, #f9f5f5 0%, #fee8ca 100%);
        margin-top: 30px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        span:nth-child(1) {
          font-size: 18px;
          color: #9b9b9b;
          margin-right: 68px;
        }
        span:nth-child(2) {
          font-size: 42px;
          color: #e55623;
          margin-right: 28px;
        }
        span:nth-child(3) {
          color: #9b9b9b;
          font-size: 24px;
          text-decoration: line-through;
        }
      }
      &_delivery,
      &_sales,
      &_quantity {
        color: #9b9b9b;
        font-size: 18px;
        margin-top: 37px;
      }
      &_buy .el-button {
        width: 184px;
        height: 56px;
        font-size: 26px;
        color: #e55623;
        background-color: #fff7ed;
        border: 1px solid #e55623;
        margin-top: 50px;
      }
    }
  }
  .richText {
    margin-top: 74px;
    &_title {
      text-align: center;
      height: 58px;
      line-height: 58px;
      color: #333333;
      font-size: 24px;
      background: #f5f5f5;
    }
    &_text {
      margin-top: 34px;
    }
  }
  .love {
    margin-top: 50px;
    &_title {
      text-align: center;
      height: 58px;
      line-height: 58px;
      color: #333333;
      font-size: 24px;
      background: #f5f5f5;
      margin-bottom: 26px;
    }
    &_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-li {
        padding: 27px 30px 0;
        box-sizing: border-box;
        width: 250px;
        height: 332px;
        background: #ffffff;
        box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 14px;

        &-img {
          width: 190px;
          height: 190px;
          margin-bottom: 10px;
          margin: 0 auto;
        }
        &-title {
          font-size: 16px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &-price {
          color: #d81e06;
          font-size: 24px;
        }
      }
      &-li:hover {
        transform: scale(1.1);
        transition: transform 1s;
        cursor: pointer;
      }
    }
  }
}
.order {
  padding: 48px 115px 0;
  &_hader {
    display: flex;
    padding-bottom: 48px;
    border-bottom: 1px dashed #fdb75e;
    &_img {
      width: 240px;
      height: 240px;
      margin-right: 54px;
    }
    &_title {
      color: #333333;
      font-size: 30px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &_price {
      color: #e55623;
      font-size: 42px;
    }
    &_quantity {
      font-size: 24px;
      color: #4a4a4a;
    }
    &_text {
      margin-top: 14px;
      span {
        font-size: 24px;
        margin-right: 20px;
      }
      span:nth-child(3) {
        color: #e55623;
      }
    }
  }
  &_address {
    margin-top: 23px;
    padding: 28px 93px;
    background-color: #fff;
    border: 1px solid #e55623;
    background: #fff7ed;
    .setAddress {
      margin-left: 23px;
      color: #e55623;
      font-size: 18px;
    }
    .setAddress:hover {
      cursor: pointer;
      color: #ccc;
    }
  }
}
</style>
<style scoped>
.buyDialog >>> .el-dialog__header {
  background-color: #fdb75e;
}
.buyDialog >>> .el-dialog__title {
  color: #fff;
}
.order_address >>> .el-select .el-input.is-focus .el-input__inner,
.order_address >>> .el-select .el-input__inner:focus {
  border: 1px solid #e55623;
}
.order_address >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #e55623;
  border-color: #e55623;
}
.order_address >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #e55623;
}
.order_address >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #e55623;
  background: #e55623;
}
.order_address >>> .el-radio__input.is-checked + .el-radio__label {
  color: #e55623;
}
</style>
