import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/base.css'
import './assets/css/all.css'
import $http from './api/api.js'
import sessionStorage from './utils/sessionStorage.js'
import localStorage from './utils/localStorage.js'
import utils from './utils/utils.js'
import WebIM from './utils/WebIM.js'

Vue.config.productionTip = false

Vue.prototype.$http = $http
Vue.prototype.$sessionStorage = sessionStorage
Vue.prototype.$localStorage = localStorage
Vue.prototype.$utils = utils
Vue.prototype.$WebIM = WebIM

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
