<template>
	<el-dialog
		title="完善信息"
		:visible.sync="show"
		width="30%"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
		:show-close="false"
	>
		<el-form :model="mobileForm" :rules="rules" ref="mobileForm">
			<el-form-item
				label="手机号:"
				prop="mobile"
				:label-width="formLabelWidth"
			>
				<el-input
					v-model="mobileForm.mobile"
					autocomplete="off"
				></el-input>
			</el-form-item>
			<el-form-item
				label="验证码:"
				prop="captcha"
				:label-width="formLabelWidth"
			>
				<el-input
					v-model="mobileForm.captcha"
					autocomplete="off"
				></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button
				type="primary"
				:loading="submitLoading"
				@click="submit"
			>
				提交
			</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	name: 'navMenu',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mobileForm: {
				mobile: '',
				captcha: '',
			},
			rules: {
				mobile: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
				],
				captcha: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				],
			},
			formLabelWidth: '70px',
			submitLoading: false,
			captchaLoading: false,
		}
	},
	methods: {
		submit() {
			this.$refs['mobileForm'].validate(valid => {
				if (!valid) return false
				this.submitLoading = true
			})
		},
	},
}
</script>

<style scoped lang="scss">
.el-form {
	padding: 0 30px;
}
</style>
