<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/uploadPlan' }"
          style="font-size: 30px"
          replace
        >
          策划案上传
        </el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="details">
        <el-form
          ref="form"
          :model="form"
          label-width="7em"
          :disabled="disabled"
        >
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="文章内容">
            <quill-editor
              v-if="!disabled"
              ref="text"
              v-model="form.content"
              class="myQuillEditor"
              :options="editorOption"
            />
            <div v-else v-html="form.content"></div>
          </el-form-item>
          <el-form-item label="策划案展示图" style="margin-top: 120px">
            <el-upload
              :action="uploadURL"
              list-type="picture-card"
              :limit="1"
              :on-exceed="handleExceed"
              :headers="uploadHeaders"
              :file-list="fileList"
              :multiple="false"
              :on-success="upSuccess"
              :on-remove="upremove"
              accept="image/*"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="分类"
            ><el-cascader v-model="value" :options="options"></el-cascader>
          </el-form-item>
          <el-form-item label="营销日历节日">
            <el-checkbox-group v-model="form.calendar_ids">
              <el-checkbox
                :label="item.id"
                v-for="item in calendarList"
                :key="item.id"
                >{{ item.events }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="but">
      <el-button type="primary" @click="submit" v-if="!disabled"
        >保存</el-button
      >
      <el-button
        type="primary"
        @click="disabled = false"
        v-if="disabled && form.verify_status.key !== 2"
        >编辑</el-button
      >
      <el-button type="primary" @click="disabled = true" v-if="!disabled"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import configure from '../../utils/configure.js'

export default {
  data() {
    return {
      id: null,
      disabled: true,
      uploadURL: configure.uploadURL,
      form: {
        title: '',
        case_image: '',
        calendar_ids: new Array(),
        case_category1_id: '',
        case_category2_id: '',
        case_category3_id: 1,
        content: '',
        verify_status: new Object(),
      },
      uploadHeaders: {
        token: localStorage.getItem('token'),
      },
      options: new Array(),
      value: new Array(),
      calendarList: new Array(),
      editorOption: {
        // editorOption里是放图片上传配置参数用的，例如：
        action: configure.uploadURL, // 必填参数 图片上传地址
        methods: 'post', // 必填参数 图片上传方式
        token: localStorage.getItem('token'), // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
        name: 'file', // 必填参数 文件的参数名
        // size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
        accept:
          'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg', // 可选 可上传的图片格式
      },
      fileList: [],
    }
  },
  components: {
    quillEditor,
  },
  computed: {},
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getcasescategorylist()
      this.getcalendarlist()
      this.getCaseDetails()
    }
  },
  watch: {
    value(e) {
      this.form.case_category1_id = e[0]
      this.form.case_category2_id = e[1]
    },
    disabled(e) {
      if (e) {
        this.$router.go(0)
      }
    },
  },
  methods: {
    getCaseDetails() {
      this.loading = true
      this.$http.plan
        .getCaseDetails({
          id: this.id,
        })
        .then((res) => {
          this.form = res.data
          if (!res.data.calendar_ids) this.form.calendar_ids = new Array()
          this.form.calendar_ids = res.data.calendar_ids.split(',').map(Number)
          this.fileList = [{ url: res.data.case_image }]
          this.value[0] = res.data.case_category1_id - 0
          this.value[1] = res.data.case_category2_id - 0
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 提交策划案
    submit() {
      this.form.calendar_ids = this.form.calendar_ids.toString()
      this.$http.plan.updateCase({ ...this.form, id: this.id }).then((res) => {
        this.disabled = true
        this.$message.success(`保存成功`)
      })
    },
    //图片上传成功
    upSuccess(e) {
      console.log(e)
      this.form.case_image = e.data.fullurl
    },
    //图片删除
    upremove(e) {
      this.form.case_image = ''
    },
    // 获取分类
    getcasescategorylist() {
      this.$http.plan.getcasescategorylist().then((res) => {
        console.log(res)
        this.options = res.data
      })
    },
    // 获取节日
    getcalendarlist() {
      this.$http.calendar.getcalendarlist().then((res) => {
        console.log(res)
        this.calendarList = res.data.list
      })
    },
    // 上传超过数量回调
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一张图片`)
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 10px 350px 15px;
  overflow: auto;
  height: 630px;
  position: relative;
}
.but {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #4086fc;
}
.details {
  .myQuillEditor {
    height: 300px !important;
  }
}
</style>
