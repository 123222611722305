<template>
	<div class="register">
		<el-form
			label-width="80px"
			:model="ruleForm"
			:rules="rules"
			ref="ruleForm"
			status-icon
		>
			<el-form-item label="手机号:" prop="mobile">
				<el-input
					v-model="ruleForm.mobile"
					placeholder="请输入手机号"
				></el-input>
			</el-form-item>
			<el-form-item label="验证码:" prop="code">
				<div class="verification">
					<el-input
						v-model="ruleForm.code"
						placeholder="请输入短信验证码"
					></el-input>
					<el-button
						type="primary"
						:loading="captchaLoading"
						@click="getCaptcha"
					>
						获取验证码
					</el-button>
				</div>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input
					type="password"
					v-model="ruleForm.password"
					placeholder="请输入不少于8位英文字母和数字结合的登录密码"
					show-password
					autocomplete="off"
				></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="checkPassword">
				<el-input
					type="password"
					v-model="ruleForm.checkPassword"
					placeholder="请再次输入登录密码"
					show-password
					autocomplete="off"
				></el-input>
			</el-form-item>
			<el-form-item class="protocol text-center ">
				<el-checkbox v-model="checked" />
				我已经同意并接收
				<span>《用户协议》</span>
				和
				<span>《隐私协议》</span>
			</el-form-item>
			<el-form-item class="text-center">
				<el-button
					class="registerBut"
					type="primary"
					:loading="loading"
					@click="submit"
				>
					免费注册
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: 'register',
	props: {},
	data() {
		const validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'))
			} else {
				if (this.ruleForm.checkPassword !== '') {
					this.$refs.ruleForm.validateField('checkPassword')
				}
				callback()
			}
		}
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			ruleForm: {
				mobile: '',
				password: '',
				checkPassword: '',
				code: '',
			},
			rules: {
				mobile: [
					{required: true, message: '请输入手机号', trigger: 'blur'},
				],
				password: [{validator: validatePass, trigger: 'blur'}],
				checkPassword: [{validator: validatePass2, trigger: 'blur'}],
				code: [
					{required: true, message: '请输入验证码', trigger: 'blur'},
				],
			},
			checked: false,
			captchaLoading: false,
			loading: false,
		}
	},
	methods: {
		submit() {
			this.$refs['ruleForm'].validate(valid => {
				if (!valid) return false
				if (!this.checked) {
					this.$message.error(
						'请勾选“我已经同意并接收《用户协议》和《隐私协议》”'
					)
					return false
				}
				this.loading = true
				this.$http.user
					.register(this.ruleForm)
					.then(res => {
						this.loading = false
						this.$message.success('注册成功。')
						this.$emit('register')
					})
					.catch(res => {
						this.loading = false
					})
			})
		},
		// 获取验证码
		getCaptcha() {
			if (this.ruleForm.mobile) {
				this.captchaLoading = true
				this.$http.user
					.mobileSend({
						mobile: this.ruleForm.mobile,
						event: 'register',
					})
					.then(res => {
						console.log(res)
						this.$message.success('验证码发送成功。')
						this.captchaLoading = false
					})
					.catch(res => {
						this.captchaLoading = false
					})
			} else {
				this.$message.error('请输入正确的手机号。')
			}
		},
	},
}
</script>

<style scoped>
.register {
	padding: 0 130px;
}
.verification {
	display: flex;
}
.el-input {
	margin-right: 10px;
}
.protocol span {
	color: #509dec;
	cursor: pointer;
}
.registerBut {
	width: 100%;
	height: 88px;
	border-radius: 43px;
	font-size: 34px;
}
</style>
