import axios from '../../utils/axios.js'

const user = {
	// 密码登录
	login(data) {
		return axios({
			method: 'post',
			url: '/api/user/login',
			data: data
		})
	},
	// 发送验证码	事件名称（mobilelogin=短信登录，register=注册，changemobile=修改手机号，resetpwd=重置密码）
	mobileSend(data) {
		return axios({
			method: 'post',
			url: '/api/sms/send',
			data: data
		})
	},
	// 手机验证码登录
	mobilelogin(data) {
		return axios({
			method: 'post',
			url: '/api/user/mobilelogin',
			data: data
		})
	},
	// 修改手机号 /api/user/changemobile
	changemobile(data) {
		return axios({
			method: 'post',
			url: '/api/user/changemobile',
			data: data
		})
	},
	// 获取扫码登录参数 
	getLoginParams() {
		return axios({
			method: 'get',
			url: '/api/wechat/getLoginParams'
		})
	},
	// 	 获取用户基本信息（实时余额）
	getuserinfo() {
		return axios({
			method: 'get',
			url: '/api/user/getuserinfo',
		})
	},
	// 检测Token是否过期 
	tokenCheck() {
		return axios({
			method: 'get',
			url: '/api/token/check',
		})
	},
	// 注册会员
	register(data) {
		return axios({
			method: 'post',
			url: '/api/user/register',
			data: data
		})
	},
	// 获取IM信息
	getUserIm(data) {
		return axios({
			method: 'get',
			url: '/api/im/getUserIm',
			data: data
		})
	},
}
export default user
