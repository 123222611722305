<template>
  <el-menu
    :default-active="defaultActive"
    class="el-menu-demo"
    mode="horizontal"
    router
    @select="handleSelect"
  >
    <el-menu-item index="/my">我的账户</el-menu-item>
    <!-- <el-menu-item>本月推荐策划案</el-menu-item> -->
    <el-menu-item @click="customerService">联系客服</el-menu-item>
    <!-- <el-menu-item>意见反馈</el-menu-item> -->
    <!-- <el-menu-item>常见问题</el-menu-item> -->
  </el-menu>
</template>

<script>
export default {
  name: 'navMenu',
  props: {},
  data() {
    return {
      defaultActive: '',
    }
  },
  created() {
    this.defaultActive = `/${this.$route.path.split('/')[1]}`
  },
  watch: {
    $route(to, from) {
      this.defaultActive = `/${to.path.split('/')[1]}`
    },
  },
  methods: {
    handleSelect() {
      this.$emit('handleSelect')
    },
    customerService() {
      this.$alert(
        '<img src="https://bkhx.oss-cn-beijing.aliyuncs.com/bkhx_xcx/images/my/image/kefu_erweima.png"style="width: 100px;height: 100px;margin: 0 auto"/>' +
          '<div style="text-align: center;">如何您对我们有任何疑问或投诉，可用微信扫以上二维码联系我们，我们会尽快为您解决！</div>',
        '扫码二维码联系客服',
        {
          dangerouslyUseHTMLString: true,
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.el-menu-item {
  float: right !important;
}
</style>
