<template>
	<div class="box" v-loading="loading">
		<div class="title">深度定制</div>
		<div class="content">
			<router-link to="/planCustomize/planCustomize_add">
				<el-button type="primary" icon="el-icon-plus" size="small">
					新建定制策划案
				</el-button>
			</router-link>
			<div class="filter">
				<el-select
					v-model="status"
					placeholder="全部状态"
					size="small"
				>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
				<el-date-picker
					v-model="time"
					type="daterange"
					align="right"
					size="small"
					unlink-panels
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd"
				></el-date-picker>
				<el-input
					style="width: 280px;"
					v-model="search"
					placeholder="请输入策划案名称"
					size="small"
				></el-input>
				<el-button type="primary" size="small" @click="getSearch">
					查询
				</el-button>
				<el-button size="small" @click="reset">重置</el-button>
			</div>
			<div class="table">
				<el-table
					stripe
					:data="tableData.list"
					border
					size="mini"
					max-height="590"
					height="590"
				>
					<el-table-column
						prop="id"
						label="序号"
						width="60"
						:resizable="false"
					></el-table-column>
					<el-table-column
						prop="title"
						label="策划案名称"
						:resizable="false"
					></el-table-column>
					<el-table-column
						label="表单内容"
						:resizable="false"
						width="70"
					>
						<template slot-scope="scope">
							<el-button
								type="text"
								size="small"
								@click="formDetalis(scope.row)"
							>
								查看
							</el-button>
						</template>
					</el-table-column>
					<el-table-column
						prop="createtime"
						label="创建时间"
						width="140"
						:resizable="false"
					></el-table-column>
					<el-table-column
						prop="complete_time"
						label="完成时间"
						:resizable="false"
						width="140"
					></el-table-column>
					<el-table-column
						prop="pay_money"
						label="付款金额"
						width="100"
						:resizable="false"
					></el-table-column>
					<el-table-column
						prop="status.value"
						label="状态"
						width="90"
						:resizable="false"
					></el-table-column>
					<el-table-column label="操作编辑" :resizable="false">
						<template slot-scope="scope">
							<el-button
								@click.native.prevent="details(scope.row)"
								type="text"
								size="small"
								v-if="
									scope.row.status.key === 3 ||
										scope.row.status.key === 4
								"
							>
								查看策划案
							</el-button>
							<el-popconfirm
								title="是否申请退款?"
								confirm-button-text="是"
								cancel-button-text="否"
								@confirm="confirm(scope.row)"
								v-if="scope.row.status.key === 2"
							>
								<el-button type="text" size="small" slot="reference">
									申请退款
								</el-button>
							</el-popconfirm>

							<el-button
								@click.native.prevent="download(scope.row)"
								type="text"
								size="small"
								v-if="scope.row.status.key === 4"
							>
								下载
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination
					style="text-align: right;"
					background
					layout="prev, pager, next"
					:total="tableData.total_count"
					:page-size="pageSize"
					:current-page="current_page"
					@current-change="currentChange"
				></el-pagination>
			</div>
		</div>
		<!-- 表单 -->
		<el-dialog title="表单内容" :visible.sync="formShow" width="50%">
			<el-form
				label-position="top"
				disabled
				style="overflow:auto;height: 500px;"
			>
				<el-form-item
					:label="item.title"
					v-for="(item, i) in formData"
					:key="i"
				>
					<!-- 输入框3 -->
					<el-input
						v-model="item.value"
						v-if="item.field_type == 3"
					></el-input>
					confirm
					<!-- 单选1 -->
					<div v-if="item.field_type == 1">
						<el-radio
							:label="radioItem.value"
							v-for="(radioItem, radioIndex) in item.select_option"
							:key="radioItem.key"
							v-if="item.value == radioItem.key"
							:value="radioItem.value"
						></el-radio>
					</div>
					<!-- 多选2 -->
					<div v-if="item.field_type == 2">
						<el-checkbox
							:value="true"
							v-for="(checkboxItem, checkboxIndex) in item.value"
							:key="checkboxIndex"
							:label="checkboxItem.value"
						></el-checkbox>
					</div>
					<!-- 时间选择4 -->
					<el-input
						v-model="item.value"
						v-if="item.field_type == 4"
					></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="formShow = false" type="primary">
					关 闭
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
	name: 'plan_my',
	data() {
		return {
			options: [
				{
					value: '',
					label: '全部',
				},
				{
					value: 2,
					label: '待处理',
				},
				{
					value: 3,
					label: '待确认',
				},
				{
					value: 4,
					label: '已完成',
				},
				{
					value: 6,
					label: '已退款',
				},
			],
			status: '',
			current_page: 1,
			pageSize: 13,
			time: [],
			search: '',
			tableData: {
				list: [],
			},
			activDetails: {},
			formShow: false,
			formData: [],
			loading: false,
		}
	},
	computed: {
		...mapState(['userInfo']),
	},
	created() {
		this.getMyCustomizedCasesList()
	},
	watch: {
		status() {
			this.current_page = 1
			this.getMyCustomizedCasesList()
		},
	},
	methods: {
		// 获取列表
		getMyCustomizedCasesList() {
			this.loading = true
			this.$http.plan
				.getMyCustomizedCasesList({
					current_page: this.current_page,
					page_size: this.pageSize,
					search: this.search,
					status: this.status,
				})
				.then(res => {
					this.tableData = res.data
					this.loading = false
				})
				.catch(res => {
					this.loading = false
				})
		},
		// 分页
		currentChange(e) {
			this.current_page = e
			this.getMyCustomizedCasesList()
		},
		// 查询
		getSearch() {
			this.current_page = 1
			this.getMyCustomizedCasesList()
		},
		// 重置数据
		reset() {
			this.status = ''
			this.current_page = 1
			this.time = []
			this.search = ''
		},
		// 查看策划案
		details(e) {
			this.$router.push({
				path: '/planCustomize/planCustomize_details',
				query: {id: e.id},
			})
		},
		// 下载
		download(e) {
			let token = this.$localStorage.getItem('token')
			window.open(
				'https://bk.qsjykj.com.cn/api/cases/download?id=' +
					e.id +
					'&type=3' +
					'&token=' +
					token
			)
		},
		// 查看表单
		formDetalis(e) {
			this.activDetails = e
			this.$http.plan
				.getMyCustomizedCasesDetails({
					id: this.activDetails.id,
				})
				.then(res => {
					this.formData = res.data.from_list
					this.formShow = true
				})
		},
		// 申请退款
		confirm(e) {
			this.$http.plan
				.applyCustomizedCasesRefund({
					id: e.id,
				})
				.then(res => {
					this.$message.success('申请成功!')
					this.getMyCustomizedCasesList()
				})
		},
	},
}
</script>

<style type="text/css" scoped lang="scss">
.box {
	box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
	width: 100%;
	height: 100%;
	border-radius: 26px;
}
.title {
	font-size: 30px;
	color: #333;
	font-weight: 700;
	padding: 15px 70px;
	border-bottom: 1px solid #4086fc;
}
.content {
	padding: 15px 70px;
}
.filter {
	display: flex;
	margin-top: 20px;
	> * {
		margin-right: 20px;
	}
}
.table {
	margin-top: 20px;
}
</style>
