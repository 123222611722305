<template>
  <div class="box" v-loading="loading">
    <div class="title">我的账户</div>
    <div class="content">
      <div class="balance">
        <div class="balance_title">账户信息</div>
        <div class="balance_card">
          <el-card class="box-card">
            <div>{{userInfo.money}}元</div>
            <div>可用余额</div>
            <div>
              <!-- <el-button size="mini" type="primary" round>明细</el-button>
              <el-button size="mini" type="primary" round>充值</el-button>
              <el-button size="mini" type="primary" round>提现</el-button> -->
            </div>
          </el-card>
          <el-card class="box-card">
            <div>{{userInfo.score}}</div>
            <div>可用积分</div>
            <div>
              <!-- <el-button size="mini" type="primary" round>明细</el-button> -->
            </div>
          </el-card>
        </div>
      </div>
      <div class="userInfo">
        <div class="userInfo_title">基础信息</div>
        <div class="userInfo_card">
          <el-card>
            <div class="userInfo_card-li">
              <span>用户昵称</span>
              <span>{{userInfo.nickname}}</span>
              <!-- <el-button type="text" style="font-size: 18px">修改</el-button> -->
            </div>
            <div class="userInfo_card-li">
              <span>绑定手机</span>
              <span>{{userInfo.mobile}}</span>
              <!-- <el-button type="text" style="font-size: 18px">修改</el-button> -->
            </div>
            <div class="userInfo_card-li">
              <span>修改密码</span>
              <span></span>
              <!-- <el-button type="text" style="font-size: 18px">修改</el-button> -->
            </div>
            <div class="userInfo_card-li">
              <span>接收消息邮箱</span>
              <span>{{userInfo.email}}</span>
              <!-- <el-button type="text" style="font-size: 18px">修改</el-button> -->
            </div>
            <div class="userInfo_card-li">
              <span>企业名称</span>
              <span>{{userInfo.enterprise}}</span>
              <!-- <el-button type="text" style="font-size: 18px">修改</el-button> -->
            </div>
          </el-card>
        </div>
      </div>
      <div class="quit text-center">
        <el-button type="primary" @click="quit">注销登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      id: null,
      loading: false,
    }
  },
  computed: {
    // 使用模块中的状态量时需要解构模块名，而不是状态量
    ...mapState(['userInfo']),
  },
  components: {},
  created() {},
  watch: {},
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    quit() {
      this.$confirm('是否注销登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await this.$localStorage.removeItem('token')
          this.keepUserInfo(new Object())
          this.$WebIM.conn.close()
          this.$message.success('退出成功！')
          this.$router.replace({ path: '/' })
        })
        .catch(() => {})
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  position: relative;
  padding: 50px 250px 10px;
  height: 730px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
}
.balance {
  padding-bottom: 47px;
  border-bottom: 1px dashed #979797;
  &_title {
    font-size: 20px;
    color: #333333;
    font-weight: 700;
  }
  &_card {
    display: flex;
    justify-content: center;
    align-content: center;
    .box-card {
      width: 400px;
      height: 220px;
      margin: 0 30px;
      text-align: center;
      div:nth-child(1) {
        font-size: 40px;
        color: #333333;
        font-weight: 700;
        margin-top: 17px;
      }
      div:nth-child(2) {
        font-size: 20px;
        color: #9b9b9b;
      }
      div:nth-child(3) {
        margin-top: 38px;
      }
    }
  }
}
.userInfo {
  margin-top: 40px;
  &_title {
    font-size: 20px;
    color: #333333;
    font-weight: 700;
  }
  &_card {
    padding: 0 280px;
    &-li {
      font-size: 18px;
      padding-bottom: 14px;
      padding-top: 14px;
      border-bottom: 1px solid #f9f9f9;
      display: flex;
      align-items: center;
      padding-right: 30px;
      span:nth-child(1) {
        font-weight: 700;
        width: 7em;
      }
      span:nth-child(2) {
        color: #4a4a4a;
        flex: 1;
      }
    }
  }
}
.quit {
  margin-top: 20px;
}
</style>
