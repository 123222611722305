<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/mall' }"
          style="font-size: 30px"
          replace
        >
          礼品采购
        </el-breadcrumb-item>
        <el-breadcrumb-item>我的礼品订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="filter">
        <el-select
          v-model="status"
          placeholder="全部状态"
          size="small"
          style="margin: 0 10px 0 0"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        下单时间:
        <el-date-picker
          v-model="time"
          type="daterange"
          align="right"
          size="small"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="width: 230px; margin: 0 10px 0"
        ></el-date-picker>
        <el-input
          style="width: 200px; margin: 0 10px"
          v-model="name"
          placeholder="请输入商品名称"
          size="small"
        ></el-input>
        <el-button type="warning" size="small" @click="getSearch">
          查询
        </el-button>
        <el-button
          size="small"
          style="border: 1px solid #fd8927; color: #fdb75e"
          @click="reset"
        >
          重置</el-button
        >
        <!-- <el-button
          size="small"
          style="border: 1px solid #fd8927; color: #fdb75e"
          @click="getSearch"
        >
          导出列表
        </el-button> -->
      </div>
      <div class="table">
        <el-table
          stripe
          :data="tableData.list"
          border
          size="mini"
          max-height="630"
          height="630"
        >
          <el-table-column
            prop="id"
            label="序号"
            width="60"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="order_no"
            label="订单编号"
            :resizable="false"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="createtime"
            label="下单时间"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="number"
            label="订单商品数量"
            :resizable="false"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="pay_money"
            label="订单总金额"
            :resizable="false"
          ></el-table-column>
          <el-table-column label="运费" :resizable="false">
            <template slot-scope="scope">
              <span>{{
                scope.row.postage ? scope.row.postage : '免运费'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status.value"
            label="状态"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="refund_money"
            label="申请退款金额"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="refund.value"
            label="退款状态"
            :resizable="false"
          ></el-table-column>
          <el-table-column label="操作" :resizable="false" width="250">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="color: #fdb75e"
                size="small"
                @click="details(scope.row)"
              >
                查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="text-align: right"
          background
          layout="prev, pager, next"
          :total="tableData.total_count"
          :page-size="pageSize"
          :current-page="current_page"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 详情弹框 -->
    <el-dialog title="音频咨询详情" :visible.sync="details_show" width="80%">
      <div class="details">
        <div class="details_info">
          <div>
            <div>订单编号：{{ details_item.order_no }}</div>
            <div>
              {{
                `订单总金额：${details_item.pay_money}元（含运费${
                  details_item.postage ? details_item.postage : 0
                }元)`
              }}
            </div>
            <div>订单状态：{{ details_item.status.value }}</div>
            <div>订单类型：邮寄</div>
          </div>
          <div>
            <div>
              <el-button
                size="small"
                type="warning"
                :loading="loadingBut"
                v-if="details_item.status.key === 0"
                @click="getOrderPay"
                >去支付</el-button
              >
            </div>
            <div>
              <el-button
                size="small"
                style="
                  border: 1px solid #fd8927;
                  color: #fdb75e;
                  margin-top: 21px;
                "
                v-if="
                  details_item.status.key == 1 && details_item.refund.key === 0
                "
                @click="refundShow"
              >
                申请退款
              </el-button>
            </div>
          </div>
        </div>
        <div class="addressInfo">
          <el-table
            stripe
            :data="[details_item.user_data]"
            border
            size="mini"
            align="center"
          >
            <el-table-column
              prop="name"
              label="姓名"
              :resizable="false"
            ></el-table-column>
            <el-table-column
              prop="mobile"
              label="手机号"
              :resizable="false"
              align="center"
            ></el-table-column>
            <el-table-column label="详细地址" :resizable="false" align="center">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.mailing_address }}{{ scope.row.address }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="goodsInfo">
          <div>订单商品</div>
          <el-table stripe :data="details_item.order_data" border size="mini">
            <el-table-column label="商品图片" :resizable="false" align="center">
              <template slot-scope="scope">
                <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.main_image_show"
                  fit="contain"
                  :preview-src-list="[scope.row.main_image_show]"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              label="商品名称"
              prop="title"
              :resizable="false"
              align="center"
              width="712"
            ></el-table-column>
            <el-table-column
              label="单价"
              prop="price"
              :resizable="false"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="数量"
              prop="qty"
              :resizable="false"
              align="center"
            >
            </el-table-column>
            <el-table-column label="总价" :resizable="false" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.price * scope.row.qty }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="logisticsInfo" v-if="0">
          <div>物流信息</div>
          <div>快递公司：</div>
          <div>快递编号：</div>
          <el-steps :active="99" direction="vertical" :space="50">
            <el-step
              :title="`步骤${item}`"
              icon="el-icon-s-flag"
              v-for="item in 4"
              :key="item"
            ></el-step>
          </el-steps>
        </div>
      </div>
    </el-dialog>
    <!-- 支付二维码 -->
    <qrcode
      :codeShow.sync="codeShow"
      :qrcodeUrl="qrcodeUrl"
      @complete="completePay"
      @cancel="cancelPay"
    ></qrcode>
    <!-- 退款弹框 -->
    <el-dialog title="申请退款" :visible.sync="refund_show" width="30%">
      <el-form label-width="100px">
        <el-form-item label="退款原因：">
          <el-radio v-model="refund_type" label="1">商品质量问题</el-radio>
          <el-radio v-model="refund_type" label="2"
            >实际商品与平台对商品的描述不符</el-radio
          >
          <el-radio v-model="refund_type" label="3">少发/漏发</el-radio>
          <el-radio v-model="refund_type" label="4"
            >包装/商品破损/污渍</el-radio
          >
          <el-radio v-model="refund_type" label="5"
            >不太满意/朋友评价不好</el-radio
          >
          <el-radio v-model="refund_type" label="6">缺货</el-radio>
          <el-radio v-model="refund_type" label="7">不想要了</el-radio>
        </el-form-item>
        <el-form-item label="退款金额：">
          <el-input v-model="refund_money"></el-input>
        </el-form-item>
        <el-form-item label="退款备注：">
          <el-input v-model="refund_remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refund_show = false">取 消</el-button>
        <el-button type="warning" @click="shopRefund">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qrcode from '../../components/qrcode.vue'
export default {
  components: {
    qrcode,
  },
  data() {
    return {
      options: [
        {
          value: 99,
          label: '全部',
        },
        {
          value: 0,
          label: '待支付',
        },
        {
          value: 1,
          label: '待取货',
        },
        {
          value: 2,
          label: '已完成',
        },
        {
          value: 3,
          label: '取消/退款',
        },
      ],
      loading: false,
      status: 99,
      current_page: 1,
      pageSize: 15,
      name: null,
      time: new Array(),
      search: null,
      tableData: {
        list: new Array(),
      },
      details_show: false,
      details_item: new Object(),
      codeShow: false,
      qrcodeUrl: null,
      loadingBut: false,
      order_id: null,
      refund_show: false,
      refund_type: null,
      refund_money: null, //退款金额
      refund_remarks: null, //退款备注
    }
  },
  watch: {
    status() {
      this.current_page = 1
    },
  },
  created() {
    this.getOrderList()
    this.details_item.status = new Object()
  },
  computed: {
    // 配送方式
    deliveryType() {
      if (this.details_item.mail_type === 0) {
        return '不可邮寄'
      } else if (this.details_item.mail_type === 1) {
        return '免邮费'
      } else if (this.details_item.mail_type === 2) {
        return '邮寄'
      }
    },
  },
  methods: {
    getOrderList() {
      this.loading = true
      if (this.time === null) {
        this.time = new Array()
      }
      let time1 = this.time[0]
      let time2 = this.time[1]
      this.$http.mall
        .getOrderList({
          status: this.status,
          search: this.search,
          current_page: this.current_page,
          page_size: this.pageSize,
          start_time: time1,
          end_time: time2,
        })
        .then((res) => {
          console.log(res.data)
          this.tableData = res.data
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 分页
    currentChange(e) {
      this.current_page = e
      this.getOrderList()
    },
    // 查询
    getSearch() {
      this.current_page = 1
      this.getOrderList()
    },
    // 重置
    reset() {
      this.status = 99
      this.current_page = 1
      this.time = new Array()
      this.getOrderList()
    },
    // 查看详情
    details(e) {
      if (e) this.order_id = e.id
      this.$http.mall
        .getOrderDetails({
          id: this.order_id,
        })
        .then((res) => {
          console.log(res)
          this.details_item = res.data
          this.details_show = true
        })
    },
    // 支付订单
    getOrderPay() {
      this.loadingBut = true
      this.$http.mall
        .getOrderPay({
          id: this.details_item.id,
        })
        .then((res) => {
          this.qrcodeUrl = res.data.qrcode
          this.codeShow = true
          this.loadingBut = false
        })
    },
    // 取消支付
    cancelPay() {
      this.$message.error('取消支付！')
    },
    // 完成支付
    async completePay() {
      await this.details()
      if (this.details_item.status.key === 0) {
        this.$message.error('未支付！')
        this.codeShow = true
      } else {
        this.$message.success('支付成功')
        this.getOrderList()
      }
    },
    //申请退款弹框
    refundShow() {
      this.refund_show = true
      this.refund_money = null //退款金额
      this.refund_remarks = null //退款备注
    },
    // 申请退款
    shopRefund() {
      this.$http.mall
        .shopRefund({
          id: this.details_item.id,
          refund_money: this.refund_money,
          refund_remarks: this.refund_remarks,
          refund_type: this.refund_type,
        })
        .then((res) => {
          this.refund_show = false
          this.details_show = false
          this.$message.success('申请退款成功')
          this.getOrderList()
        })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 60px;
  border-bottom: 1px solid #fd8927;
}

.content {
  padding: 15px 70px;
}
.table {
  margin-top: 10px;
}
.details {
  padding: 60px 90px 0;
  height: 400px;
  overflow-y: scroll;
  &_info {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #000000;
    align-content: center;
    font-weight: 700;
  }
  .addressInfo {
    margin-top: 26px;
  }
  .goodsInfo {
    margin-top: 26px;
    > div:nth-child(1) {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .logisticsInfo {
    margin-top: 26px;
    > div {
      font-size: 16px;
      margin-top: 10px;
    }
    > div:nth-child(1) {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
<style scoped>
.box >>> .el-dialog__header {
  background-color: #fdb75e;
}
.box >>> .el-dialog__title {
  color: #fff;
}
</style>
<style scoped>
.content >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fdb75e;
  color: #fff;
}
.content >>> .el-pagination.is-background .el-pager li:hover {
  color: #fdb75e;
}
.box >>> .el-radio__input.is-checked + .el-radio__label {
  color: #fdb75e;
}
.box >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #fdb75e;
  background: #fdb75e;
}
</style>
