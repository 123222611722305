<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/planCustomize/planCustomize_my' }"
          style="font-size: 30px"
          replace
        >
          定制策划案
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增定制策划案</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form label-position="top">
        <el-form-item
          required
          :label="item.title"
          v-for="item in formData"
          :key="item.id"
        >
          <!-- 输入框3 -->
          <el-input
            v-model="item.value"
            v-if="item.field_type === 3"
            :placeholder="`请输入${item.title}`"
            @input="$forceUpdate()"
          ></el-input>
          <!-- 单选框 -->
          <el-radio-group
            v-if="item.field_type === 1"
            v-model="item.value"
            @change="$forceUpdate()"
          >
            <el-radio
              :label="radioItem.key"
              v-for="(radioItem, radioIndex) in item.select_option"
              :key="radioIndex"
            >
              {{ radioItem.value }}
            </el-radio>
          </el-radio-group>
          <!-- 多选 -->
          <div v-if="item.field_type === 2">
            <el-checkbox
              :label="checkboxItem.value"
              v-for="(checkboxItem, checkboxIndex) in item.select_option"
              :key="checkboxIndex"
              v-model="checkboxItem.checked"
              @change="$forceUpdate()"
            ></el-checkbox>
          </div>

          <!-- 时间选择 -->
          <el-date-picker
            v-if="item.field_type === 4"
            v-model="item.value"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @blur="$forceUpdate()"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          required
          :label="`积分抵扣(账户剩余积分可抵扣${userInfo.score / 100}'元)`"
        >
          <el-switch v-model="is_score"></el-switch>
        </el-form-item>
        <el-form-item
          required
          :label="`余额抵扣:(账户剩余余额可抵扣${userInfo.money}元`"
        >
          <el-switch v-model="is_money"></el-switch>
        </el-form-item>
        <el-form-item required label="金额类别">
          <el-select
            v-model="money_type"
            placeholder="请选择"
            @change="money = ''"
          >
            <el-option label="推荐金额" :value="1"></el-option>
            <el-option label="其他金额" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="推荐金额" v-if="money_type === 1">
          <el-select v-model="money" placeholder="请选择">
            <el-option
              v-for="item in moneys"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="其他金额" v-else-if="money_type === 2">
          <!-- 输入框3 -->
          <el-input
            v-model="money"
            maxlength="10"
            placeholder="请输入定制金额"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button @click="getFromField">重置</el-button>
      <el-button type="primary" @click="submit" :loading="loadingBut">
        提交
      </el-button>
    </div>
    <!-- 支付二维码 -->
    <qrcode
      :codeShow.sync="codeShow"
      :qrcodeUrl="qrcodeUrl"
      @complete="completePay"
      @cancel="cancelPay"
    ></qrcode>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import qrcode from '../../components/qrcode.vue'
export default {
  name: 'plan_my',
  data() {
    return {
      formData: new Array(),
      loading: false,
      loadingBut: false,
      money_type: 1,
      money: null,
      moneys: new Array(),
      is_score: false,
      is_money: false,
      codeShow: false,
      qrcodeUrl: null,
    }
  },
  components: {
    qrcode,
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    this.getFromField()
    this.getCustomizedMoney()
  },
  watch: {},
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    // 获取表单
    getFromField() {
      this.money_type = 1
      this.money = null
      this.is_score = false
      this.is_money = false
      this.$http.common
        .getFromField({
          type: 3,
        })
        .then((res) => {
          this.formData = res.data
          this.formData.forEach((item, i) => {
            item.value = null
            item.field_id = item.id
            if (item.select_option) {
              item.select_option = JSON.parse(item.select_option)
              if (item.field_type == 2) {
                item.select_option.forEach((resItem, index) => {
                  item.select_option[index].checked = false
                })
              }
            }
          })
        })
    },
    getCustomizedMoney() {
      this.$http.plan.getCustomizedMoney().then((res) => {
        this.moneys = res.data.money
      })
    },
    submit() {
      this.loadingBut = true
      let data = {
        from_id: this.formData[0].from_id,
        data: [],
      }
      this.formData.forEach((item, i) => {
        if (item.select_option && item.field_type == 2) {
          item.value = []
          item.select_option.forEach((resItem, index) => {
            if (resItem.checked) {
              this.formData[i].value.push({
                value: resItem.value,
                key: resItem.key,
              })
            }
          })
        }
        data.data.push({
          field_id: item.field_id,
          value: item.value,
        })
      })
      data.type = this.money_type
      data.money = this.money
      data.is_score = this.is_score ? 1 : 0
      data.is_money = this.is_money ? 1 : 0
      data.method = 'scan'
      this.$http.plan
        .submitCustomizedCases(data)
        .then((res) => {
          this.loadingBut = false
          if (res.data.is_pay) {
            this.codeShow = true
            this.qrcodeUrl = res.data.pay.qrcode
          } else {
            this.$message.success('积分/余额抵扣成功，无需支付。')
          }
          this.getuserinfo()
        })
        .catch((res) => {
          this.loadingBut = false
        })
    },
    // 取消支付
    cancelPay() {
      this.$message.error('取消支付！')
      this.$router.push('/planCustomize/planCustomize_my')
    },
    // 完成支付
    completePay() {
      this.$router.push('/planCustomize/planCustomize_my')
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 15px 70px;
  overflow: auto;
  height: 670px;
}
.but {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #4086fc;
}
</style>
