<template>
	<div id="app"><router-view></router-view></div>
</template>

<script>
export default {
	name: 'app',
	data() {
		return {}
	},
	beforeMount() {
	},
	methods: {
		
	},
}
</script>

<style>
.el-dialog__header {
	background-color: #f5f5f5;
	padding: 20px!important;
}
.el-container {
  min-width: 1487px!important;
	background: #fff;
}
.el-cascader-menu__list {
  height: 300px;
}
</style>
