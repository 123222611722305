<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/uploadPlan' }"
          style="font-size: 30px"
          replace
        >
          策划案上传
        </el-breadcrumb-item>
        <el-breadcrumb-item>上传</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="hint">
        可上传原创策划案，上传后审核通过可在平台中提供给其他用户使用，每使用一次可获取对应积分奖励！奖励可在商城使用购买策划案、购买礼品和咨询等！
      </div>
      <el-form label-position="top">
        <el-form-item
          required
          :label="item.title"
          v-for="item in formData"
          :key="item.id"
        >
          <!-- 输入框3 -->
          <el-input
            v-model="item.value"
            v-if="item.field_type === 3"
            :placeholder="`请输入${item.title}`"
            @input="$forceUpdate()"
          ></el-input>
          <!-- 单选框 -->
          <el-radio-group
            v-if="item.field_type === 1"
            v-model="item.value"
            @change="$forceUpdate()"
          >
            <el-radio
              :label="radioItem.key"
              v-for="(radioItem, radioIndex) in item.select_option"
              :key="radioIndex"
            >
              {{ radioItem.value }}
            </el-radio>
          </el-radio-group>
          <!-- 多选 -->
          <div v-if="item.field_type == 2">
            <el-checkbox
              :label="checkboxItem.value"
              v-for="(checkboxItem, checkboxIndex) in item.select_option"
              :key="checkboxIndex"
              v-model="checkboxItem.checked"
              @change="$forceUpdate()"
            ></el-checkbox>
          </div>
          <!-- 时间选择 -->
          <el-date-picker
            v-if="item.field_type === 4"
            v-model="item.value"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            @blur="$forceUpdate()"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="details">
        <div class="details_title">策划案详情</div>
        <el-form ref="form" :model="form" label-width="7em">
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="文章内容">
            <quill-editor
              ref="text"
              v-model="form.content"
              class="myQuillEditor"
              :options="editorOption"
            />
          </el-form-item>
          <el-form-item label="策划案展示图" style="margin-top: 120px">
            <el-upload
              :action="uploadURL"
              list-type="picture-card"
              :headers="uploadHeaders"
              :multiple="false"
              :on-success="upSuccess"
              :on-remove="upremove"
              :limit="1"
              :on-exceed="handleExceed"
              accept="image/*"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="分类"
            ><el-cascader v-model="value" :options="options"></el-cascader>
          </el-form-item>
          <el-form-item label="营销日历节日">
            <el-checkbox-group v-model="form.calendar_ids">
              <el-checkbox
                :label="item.id"
                v-for="item in calendarList"
                :key="item.id"
                >{{ item.events }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="but">
      <el-button @click="getFromField">重置</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import configure from '../../utils/configure.js'

export default {
  data() {
    return {
      uploadURL: configure.uploadURL,
      formData: new Array(),
      form: {
        title: '',
        case_image: '',
        calendar_ids: new Array(),
        case_category1_id: '',
        case_category2_id: '',
        content: '',
      },
      uploadHeaders: {
        token: localStorage.getItem('token'),
      },
      options: new Array(),
      value: new Array(),
      calendarList: new Array(),
      editorOption: {
        // editorOption里是放图片上传配置参数用的，例如：
        action: configure.uploadURL, // 必填参数 图片上传地址
        methods: 'post', // 必填参数 图片上传方式
        token: localStorage.getItem('token'), // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
        name: 'file', // 必填参数 文件的参数名
        // size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
        accept:
          'multipart/form-data, image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/jpg', // 可选 可上传的图片格式
      },
    }
  },
  components: {
    quillEditor,
  },
  computed: {},
  created() {
    this.getFromField()
    this.getcasescategorylist()
    this.getcalendarlist()
  },
  watch: {
    value(e) {
      this.form.case_category1_id = e[0]
      this.form.case_category2_id = e[1]
    },
  },
  methods: {
    getFromField() {
      this.loading = true
      this.$http.common
        .getFromField({
          type: 2,
        })
        .then((res) => {
          this.formData = res.data
          this.formData.forEach((item) => {
            item.value = null
            item.field_id = item.id
            if (item.select_option) {
              item.select_option = JSON.parse(item.select_option)
              if (item.field_type == 2) {
                item.select_option.forEach((resItem, index) => {
                  item.select_option[index].checked = false
                })
              }
            }
          })
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 提交策划案
    submit() {
      this.loading = true
      let data = {
        from_id: this.formData[0].from_id,
        data: new Array(),
        ...this.form,
      }
      this.formData.forEach((item, i) => {
        if (item.select_option && item.field_type == 2) {
          item.value = new Array()
          item.select_option.forEach((resItem) => {
            if (resItem.checked) {
              this.formData[i].value.push({
                value: resItem.value,
                key: resItem.key,
              })
            }
          })
        }
        data.data.push({
          field_id: item.field_id,
          value: item.value,
        })
      })
      data.calendar_ids = this.form.calendar_ids.toString()
      this.$http.plan.uploadCase(data).then((res) => {
        this.$message.success(`上传成功`)
        this.$router.go(-1)
      })
    },
    //图片上传成功
    upSuccess(e) {
      console.log(e)
      this.form.case_image = e.data.fullurl
    },
    //图片删除
    upremove(e) {
      this.form.case_image = ''
    },
    // 获取分类
    getcasescategorylist() {
      this.$http.plan.getcasescategorylist().then((res) => {
        console.log(res)
        this.options = res.data
      })
    },
    // 获取节日
    getcalendarlist() {
      this.$http.calendar.getcalendarlist().then((res) => {
        console.log(res)
        this.calendarList = res.data.list
      })
    },
    // 上传超过数量回调
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传一张图片`)
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  padding: 65px 350px 15px;
  overflow: auto;
  height: 670px;
  position: relative;
}
.but {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #4086fc;
}
.hint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #333333;
  background-color: #ecf2fe;
}
.details {
  &_title {
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 20px;
    color: #333333;
    background-color: #f5f5f5;
  }
  .myQuillEditor {
    height: 300px !important;
  }
}
</style>
