<template>
  <div class="box" v-loading="loading">
    <div class="title">在线咨询</div>
    <div class="content">
      <router-link to="/consult/consult_audio_list">
        <el-button type="primary" icon="el-icon-service" size="small">
          音频咨询订单
        </el-button>
      </router-link>
      <router-link to="/consult/consult_graphic_list">
        <el-button type="primary" icon="el-icon-chat-line-square" size="small">
          图文咨询订单
        </el-button>
      </router-link>
      <el-input
        style="width: 300px"
        v-model="search"
        placeholder="请输入关键字或顾问名称"
        size="small"
      ></el-input>
      <el-button type="primary" size="small" @click="getSearch">
        查询
      </el-button>
      <div class="list" v-if="tableData.list.length > 0">
        <div class="list_li" v-for="item in tableData.list" :key="item.id">
          <div class="list_li_heder">
            <div class="list_li_heder_img">
              <img :src="item.avatar" />
            </div>
            <div class="list_li_heder_r">
              <div class="list_li_heder_r-name">
                <span>{{ item.name }}</span>
                <span>
                  <el-rate v-model="item.star" disabled></el-rate>
                </span>
              </div>
              <div class="list_li_heder_r-synopsis">
                简介：{{ item.profile }}
              </div>
              <div class="list_li_heder_r-label">
                <span>{{ item.label1 }}</span>
                <span>{{ item.label2 }}</span>
              </div>
            </div>
          </div>
          <div class="list_li_footer">
            <div>
              <div v-if="item.is_video_consult">
                <i class="el-icon-service" style="color: #4086fc"></i>
                音频咨询{{ item.video_consult_price }}元/分钟
              </div>
              <div v-if="item.is_image_consult">
                <i class="el-icon-chat-line-square" style="color: #61b50a"></i>
                在线咨询{{ item.image_consult_price }}元/次
              </div>
            </div>
            <div>
              <el-button
                type="primary"
                size="medium"
                @click="getConsultDetails(item.id)"
              >
                咨询
              </el-button>
            </div>
          </div>
        </div>
        <div style="width: 30%" v-if="tableData.list.length % 3 === 1"></div>
        <div
          style="width: 30%"
          v-if="
            tableData.list.length % 3 === 1 || tableData.list.length % 3 === 2
          "
        ></div>
      </div>
      <el-empty
        v-else
        description="数据为空"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        "
      ></el-empty>
      <!-- 分页 -->
      <el-pagination
        v-if="tableData.list.length > 0"
        style="text-align: right"
        background
        layout="prev, pager, next"
        :total="tableData.total_count"
        :page-size="pageSize"
        :current-page="current_page"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <el-dialog title="顾问详情" :visible.sync="detailsShow" width="1490px">
      <div class="details">
        <div class="details_l">
          <div class="details_l_heder">
            <div class="details_l_heder_img">
              <img :src="details.adviser.avatar" />
            </div>
            <div class="details_l_heder_name">
              <div>{{ details.adviser.name }}</div>
              <div>
                <el-rate v-model="details.adviser.star" disabled></el-rate>
              </div>
            </div>
          </div>
          <div class="details_l_text">
            <h3>简介：</h3>
            <p>{{ details.adviser.profile }}</p>
          </div>
          <div class="details_l_text">
            <h3>证书：</h3>
            {{ details.adviser.video_order }}
          </div>
          <div class="details_l_label">
            <span>{{ details.adviser.label1 }}</span>
            <span>{{ details.adviser.label2 }}</span>
          </div>
          <div class="details_l_footer">
            <div>收费方式</div>
            <div>
              <div v-if="details.adviser.is_video_consult">
                <i class="el-icon-service" style="color: #4086fc"></i>
                音频咨询{{ details.adviser.video_consult_price }}元/分钟
              </div>
              <div v-if="details.adviser.is_image_consult">
                <i class="el-icon-chat-line-square" style="color: #61b50a"></i>
                在线咨询{{ details.adviser.image_consult_price }}元/次
              </div>
            </div>
          </div>
        </div>
        <div class="details_r">
          <div class="details_r_title">顾问推荐策划案</div>
          <div class="details_r_list">
            <div
              class="details_r_list_li"
              v-for="item in details.adviser_case"
              :key="item.id"
            >
              <img :src="item.case_image" />
              <div>{{ item.title }}</div>
            </div>
          </div>
          <div class="details_r_count">
            <div>
              <i class="el-icon-service" style="color: #4086fc"></i>
              音频咨询次数:{{ details.adviser.video_order }}
            </div>
            <div>
              <i class="el-icon-chat-line-square" style="color: #61b50a"></i>
              在线咨询次:{{ details.adviser.image_order }}
            </div>
          </div>
          <div class="details_r_title">选择咨询方式</div>
          <div class="details_r_type">
            <div v-if="details.adviser.is_video_consult">
              <i class="el-icon-service" style="color: #4086fc"></i>
              <div>
                <div>
                  音频咨询
                  {{ details.adviser.video_consult_price }}元/次
                </div>
                <div>通过音频向顾问咨询，可预约顾问咨 询时间。</div>
              </div>
              <el-button type="primary" size="medium" @click="toForm(1)">
                预约咨询
              </el-button>
            </div>
            <div v-if="details.adviser.is_image_consult">
              <i class="el-icon-chat-line-square" style="color: #61b50a"></i>
              <div>
                <div>
                  在线咨询
                  {{ details.adviser.image_consult_price }}元/分钟
                </div>
                <div>
                  通过在线文字和图片向顾问咨询， 一次是当日09:00-18:00内都可咨询
                </div>
              </div>
              <el-button type="success" size="medium" @click="toForm(2)">
                预约咨询
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'plan_my',
  data() {
    return {
      search: '',
      current_page: 1,
      pageSize: 6,
      tableData: {
        list: [],
      },
      activity_id: '',
      detailsShow: false,
      details: {
        adviser: {},
      },
      loading: false,
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },

  created() {
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id
    }
    this.getConsultList()
  },
  methods: {
    // 查询
    getSearch() {
      this.current_page = 1
      this.getConsultList()
    },
    // 分页
    currentChange(e) {
      this.current_page = e
      this.getConsultList()
    },
    // 获取顾问列表
    getConsultList() {
      this.loading = true
      this.$http.consult
        .getConsultList({
          search: this.search,
          current_page: this.current_page,
          page_size: this.pageSize,
          activity_id: this.activity_id,
        })
        .then((res) => {
          this.tableData = res.data
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 顾问详情
    getConsultDetails(e) {
      this.$http.consult
        .getConsultDetails({
          id: e,
        })
        .then((res) => {
          this.details = res.data
          this.detailsShow = true
        })
    },
    toForm(e) {
      if (e === 1) {
        // 音频
        this.$router.push({
          path: '/consult/consult_audio_form',
          query: {
            id: this.details.adviser.id,
            video_consult_price: this.details.adviser.video_consult_price,
          },
        })
      } else if (e === 2) {
        // 图文
        this.$router.push({
          path: '/consult/consult_graphic_form',
          query: {
            id: this.details.adviser.id,
            image_consult_price: this.details.adviser.image_consult_price,
          },
        })
      }
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}
.content {
  position: relative;
  padding: 15px 40px;
  height: 730px;
  .el-button {
    margin-right: 20px;
  }
  .el-input {
    margin-right: 20px;
  }
  .list {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    &_li {
      width: 30%;
      // height: 320px;
      border-radius: 4px;
      box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.07);
      background: #ffffff;
      margin-bottom: 10px;
      padding: 20px;
      box-sizing: border-box;
      &_heder {
        display: flex;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
        &_img {
          width: 95px;
          height: 95px;
          border-radius: 50%;
          overflow: hidden;
        }
        &_r {
          flex: 1;
          margin-left: 20px;
          &-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 22px;
            color: #333;
            font-weight: 700;
            margin-bottom: 10px;
          }
          &-synopsis {
            font-size: 18px;
            color: #666666;
            margin-bottom: 10px;
            height: 108px;
          }
          &-label {
            color: #4086fc;
            font-size: 18px;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }
        }
      }
      &_footer {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        color: #666666;
      }
    }
  }
}
::v-deep .el-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0 !important;
}
.details {
  display: flex;
  &_l {
    border-right: 1px dashed #d8d8d8;
    padding: 0 65px;
    width: 50%;
    &_heder {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      &_img {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        overflow: hidden;
      }
      &_name {
        font-size: 30px;
        font-weight: 700;
        color: #333333;
        text-align: right;
      }
    }
    &_text {
      font-size: 22px;
      color: #666;
      margin-top: 10px;
    }
    &_label {
      display: flex;
      justify-content: space-around;
      color: #4086fc;
      font-size: 22px;
      padding: 17px 0;
      border-bottom: 1px solid #dddddd;
    }
    &_footer {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #666666;
      > div {
        margin: 0 10px;
        div {
          margin: 10px 0;
        }
      }
    }
  }
  &_r {
    width: 50%;
    padding: 0 65px;
    &_title {
      font-size: 24px;
      color: #4a4a4a;
      margin-bottom: 10px;
    }
    &_list {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &_li {
        width: 280px;
        img {
          width: 100%;
          height: 100px;
        }
        div {
          width: 100%;
          font-size: 18px;
          color: #888;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &_count {
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      border-top: 1px solid #dddddd;
      padding: 15px 0;
      border-bottom: 1px solid #dddddd;
      font-size: 20px;
      color: #999;
    }
    &_type {
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        i {
          font-size: 60px;
        }
        > div {
          margin-left: 24px;
          flex: 1;
          padding-right: 10px;
        }
      }
    }
  }
}
</style>
