<template>
	<div class="box" v-loading="loading">
		<div class="title">智能策划</div>
		<div class="content">
			<router-link to="/plan/plan_add"><el-button type="primary" icon="el-icon-plus" size="small">新建策划案</el-button></router-link>
			<div class="filter">
				<el-select v-model="status" placeholder="全部状态" size="small"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select>
				<el-date-picker
					v-model="time"
					type="daterange"
					align="right"
					size="small"
					unlink-panels
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd"
				></el-date-picker>
				<el-input style="width: 280px" v-model="search" placeholder="请输入策划案名称" size="small"></el-input>
				<el-button type="primary" size="small" @click="getSearch">查询</el-button>
				<el-button size="small" @click="reset">重置</el-button>
			</div>
			<div class="table">
				<el-table stripe :data="tableData.list" border size="mini" max-height="590" height="590">
					<el-table-column prop="id" label="序号" width="60" :resizable="false"></el-table-column>
					<el-table-column prop="title" label="策划案名称" :resizable="false"></el-table-column>
					<el-table-column label="表单内容" :resizable="false" width="70">
						<template slot-scope="scope">
							<el-button v-if="scope.row.type === 1" type="text" size="small" @click="formDetalis(scope.row)">查看</el-button>
							<span v-else>整文</span>
						</template>
					</el-table-column>
					<el-table-column prop="createtime" label="创建时间" width="140" :resizable="false"></el-table-column>
					<el-table-column prop="complete_time" label="完成时间" :resizable="false" width="140"></el-table-column>
					<el-table-column prop="pay_money" label="付款金额" width="100" :resizable="false"></el-table-column>
					<el-table-column prop="status.value" label="状态" width="70" :resizable="false"></el-table-column>
					<el-table-column label="操作编辑" :resizable="false">
						<template slot-scope="scope">
							<el-button @click.native.prevent="details(scope.row)" type="text" size="small" v-if="scope.row.status.key === 1 || scope.row.status.key === 3">查看策划案</el-button>
							<el-button @click.native.prevent="download(scope.row)" type="text" size="small" v-if="scope.row.status.key === 3">下载</el-button>
							<el-button @click.native.prevent="payDialog(scope.row)" type="text" size="small" v-if="scope.row.status.key === 1">支付</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination
					style="text-align: right"
					background
					layout="prev, pager, next"
					:total="tableData.total_count"
					:page-size="pageSize"
					:current-page="current_page"
					@current-change="currentChange"
				></el-pagination>
			</div>
		</div>
		<!-- 下单窗口 -->
		<el-dialog title="下单" :visible.sync="payDialogShow" width="30%" center>
			<el-form ref="form">
				<el-form-item label="策划案金额">{{ activDetails.pay_money }}元</el-form-item>
				<el-form-item :label="'剩余积分抵扣'">
					<span>{{ userInfo.score / 100 }}元</span>
					<el-switch v-model="is_score" style="float: right"></el-switch>
				</el-form-item>
				<el-form-item :label="'剩余余额抵扣'">
					<span>{{ userInfo.money }}元</span>
					<el-switch v-model="is_money" style="float: right"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="payDialogShow = false">取 消</el-button>
				<el-button type="primary" @click="pay">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 支付二维码 -->
		<qrcode :codeShow.sync="codeShow" :qrcodeUrl="qrcodeUrl" @complete="completePay" @cancel="cancelPay"></qrcode>
		<!-- 表单 -->
		<el-dialog title="表单内容" :visible.sync="formShow" width="50%">
			<el-form label-position="top" disabled style="overflow: auto; height: 500px">
				<el-form-item :label="item.title" v-for="(item, i) in formData" :key="i">
					<!-- 输入框3 -->
					<el-input v-model="item.value" v-if="item.field_type == 3"></el-input>
					<!-- 单选1 -->
					<el-radio-group v-model="item.value" v-if="item.field_type == 1">
						<el-radio :label="radioItem.key" v-for="(radioItem, radioIndex) in item.select_option" :key="radioIndex">{{ radioItem.value }}</el-radio>
					</el-radio-group>
					<!-- 多选2 -->
					<div v-if="item.field_type == 2"><el-checkbox :value="true" v-for="(checkboxItem, checkboxIndex) in item.value" :key="checkboxIndex" :label="checkboxItem.value"></el-checkbox></div>
					<!-- 时间选择4 -->
					<el-input v-model="item.value" v-if="item.field_type == 4"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer"><el-button @click="formShow = false" type="primary">关 闭</el-button></span>
		</el-dialog>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import qrcode from '../../components/qrcode.vue'
export default {
	name: 'plan_my',
	data() {
		return {
			options: [
				{
					value: 99,
					label: '全部',
				},
				{
					value: 0,
					label: '生成中',
				},
				{
					value: 1,
					label: '生成完成',
				},
				{
					value: 2,
					label: '生成失败',
				},
				{
					value: 3,
					label: '已完成',
				},
				{
					value: 4,
					label: '已过期',
				},
			],
			status: 99,
			current_page: 1,
			pageSize: 13,
			time: new Array(),
			search: null,
			tableData: {
				list: new Array(),
			},
			payDialogShow: false,
			activDetails: new Object(),
			is_score: false,
			is_money: false,
			codeShow: false,
			qrcodeUrl: null,
			formShow: false,
			formData: new Array(),
			loading: false,
		}
	},
	components: {
		qrcode,
	},
	computed: {
		...mapState(['userInfo']),
	},
	created() {
		this.getMyCasesList()
	},
	watch: {
		status() {
			this.current_page = 1
			this.getMyCasesList()
		},
	},
	methods: {
		...mapActions(['keepUserInfo']),
		getuserinfo() {
			this.$http.user.getuserinfo().then(res => {
				this.keepUserInfo(res.data)
			})
		},
		// 获取列表
		getMyCasesList() {
			this.loading = true
			this.$http.plan
				.getMyCasesList({
					status: this.status,
					current_page: this.current_page,
					page_size: this.pageSize,
					start_time: this.time[0],
					end_time: this.time[1],
					search: this.search,
				})
				.then(res => {
					this.tableData = res.data
					this.loading = false
				})
				.catch(res => {
					this.loading = false
				})
		},
		// 分页
		currentChange(e) {
			this.current_page = e
			this.getMyCasesList()
		},
		// 查询
		getSearch() {
			this.current_page = 1
			this.getMyCasesList()
		},
		// 重置
		reset() {
			this.status = 99
			this.current_page = 1
			this.time = new Array()
			this.search = null
		},
		// 查看策划案
		details(e) {
			this.$router.push({
				path: '/plan/plan_detailsPlan',
				query: {id: e.id},
			})
		},
		// 下载
		download(e) {
			let token = this.$localStorage.getItem('token')
			window.open(`https://bk.qsjykj.com.cn/api/cases/download?id=${e.id}&type=${e.type}&token=${token}`)
		},
		// 支付窗口显示
		payDialog(e) {
			this.activDetails = e
			this.payDialogShow = true
		},
		// 取消支付
		cancelPay() {
			this.getMyCasesList()
			this.codeShow = false
			this.$message.error('取消支付！')
		},
		// 完成支付
		completePay() {
			this.getMyCasesList()
			this.codeShow = false
		},
		// 支付
		pay() {
			if (this.activDetails.type === 1) {
				this.$http.plan
					.payNewCasesOrder({
						id: this.activDetails.id,
						is_score: this.is_score ? 1 : 0,
						is_money: this.is_money ? 1 : 0,
						method: 'scan',
					})
					.then(res => {
						this.payDialogShow = false
						if (res.data.is_pay) {
							this.codeShow = true
							this.qrcodeUrl = res.data.pay.qrcode
						} else {
							this.$message.success('余额或积分已抵扣完成无需支付。')
						}
						this.getuserinfo()
					})
			} else if (this.activDetails.type === 2) {
				this.$http.plan
					.payorder({
						id: this.activDetails.id,
						is_score: this.is_score ? 1 : 0,
						is_money: this.is_money ? 1 : 0,
						method: 'scan',
					})
					.then(res => {
						this.payDialogShow = false
						if (res.data.is_pay) {
							this.codeShow = true
							this.qrcodeUrl = res.data.pay.qrcode
						} else {
							this.$message.success('余额或积分已抵扣完成无需支付。')
						}
						this.getuserinfo()
					})
			}
		},
		// 查看表单
		formDetalis(e) {
			this.activDetails = e
			this.$http.plan
				.getMyCasesDetails({
					id: this.activDetails.id,
				})
				.then(res => {
					this.formData = res.data.from_list
					this.formShow = true
				})
		},
	},
}
</script>

<style type="text/css" scoped lang="scss">
.box {
	box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
	width: 100%;
	height: 100%;
	border-radius: 26px;
}
.title {
	font-size: 30px;
	color: #333;
	font-weight: 700;
	padding: 15px 70px;
	border-bottom: 1px solid #4086fc;
}
.content {
	padding: 15px 70px;
}
.filter {
	display: flex;
	margin-top: 20px;
	> * {
		margin-right: 20px;
	}
}
.table {
	margin-top: 20px;
}
</style>
