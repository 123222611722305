<template>
  <el-row class="tac">
    <el-col>
      <el-menu
        :default-active="defaultActive"
        router
        collapse-transition
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
      >
        <div class="logo">
          <img src="../assets/images/logo.png" />
        </div>
        <el-menu-item @click="collapse">
          <i
            :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            style="color: #409eff; font-size: 36px"
          ></i>
        </el-menu-item>
        <el-menu-item index="/home">
          <i class="el-icon-menu"></i>
          <span slot="title">工作台</span>
        </el-menu-item>
        <el-menu-item index="/plan">
          <i class="el-icon-document"></i>
          <span slot="title">智能策划</span>
        </el-menu-item>
        <el-menu-item index="/calendar">
          <i class="el-icon-date"></i>
          <span slot="title">营销日历</span>
        </el-menu-item>
        <el-menu-item index="/consult">
          <i class="el-icon-chat-line-round"></i>
          <span slot="title">在线咨询</span>
        </el-menu-item>
        <el-menu-item index="/planCustomize">
          <i class="el-icon-s-claim"></i>
          <span slot="title">深度定制策划案</span>
        </el-menu-item>
        <el-menu-item index="/mall">
          <i class="el-icon-present"></i>
          <span slot="title">礼品采购</span>
        </el-menu-item>
        <!-- <el-menu-item>
          <i class="el-icon-edit"></i>
          <span slot="title">海报设计</span>
        </el-menu-item>
        <el-menu-item>
          <i class="el-icon-coordinate"></i>
          <span slot="title">互动游戏</span>
        </el-menu-item> -->
        <el-menu-item index="/uploadPlan">
          <i class="el-icon-upload"></i>
          <span slot="title">策划上传</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "navMenu",
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultActive: "",
    };
  },
  created() {
    this.defaultActive = `/${this.$route.path.split("/")[1]}`;
  },
  watch: {
    $route(to, from) {
      this.defaultActive = `/${to.path.split("/")[1]}`;
    },
  },
  methods: {
    collapse() {
      this.$emit("collapse");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-menu--collapse {
  width: 82px;
}

.el-menu {
  height: 100vh;
  padding: 0 26px;
}

.logo {
  width: 82px;
  height: 82px;
  margin-top: 34px;
  margin-bottom: 35px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
