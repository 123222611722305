<template>
  <div>
    <div class="box1">
      <div class="title">精选服务</div>
      <div class="card">
        <router-link to="/plan">
          <el-card>
            <img src="../../assets/images/index/ico/zhineng.png" />
            <div>智能策划</div>
            <p>平台推荐、营销模式、活动 玩法、行业案例一应俱全</p>
          </el-card>
        </router-link>
        <router-link to="/calendar">
          <el-card>
            <img src="../../assets/images/index/ico/rili.png" />
            <div>营销日历</div>
            <p>平台推荐、营销模式、活动玩法、行业案例一应俱全</p>
          </el-card>
        </router-link>
        <router-link to="/consult">
          <el-card>
            <img src="../../assets/images/index/ico/zaixian.png" />
            <div>在线咨询</div>
            <p>平台推荐、营销模式、活动 玩法、行业案例一应俱全</p>
          </el-card>
        </router-link>
      </div>
    </div>
    <div class="box1">
      <div class="title">推荐服务</div>
      <div class="card">
        <router-link to="/planCustomize">
          <el-card>
            <img src="../../assets/images/index/ico/shendu.png" />
            <div>深度定制</div>
            <p>平台推荐、营销模式、活动 玩法、行业案例一应俱全</p>
          </el-card>
        </router-link>
        <router-link to="/mall">
          <el-card>
            <img src="../../assets/images/index/ico/lipin.png" />
            <div>礼品采购</div>
            <p>平台推荐、营销模式、活动玩法、行业案例一应俱全</p>
          </el-card>
        </router-link>
        <!-- <router-link to="/">
					<el-card>
						<img src="../../assets/images/index/ico/haibao.png" />
						<div>海报设计</div>
						<p>平台推荐、营销模式、活动 玩法、行业案例一应俱全</p>
					</el-card>
				</router-link>
				<router-link to="/">
					<el-card>
						<img src="../../assets/images/index/ico/youxi.png" />
						<div>互动游戏</div>
						<p>平台推荐、营销模式、活动 玩法、行业案例一应俱全</p>
					</el-card>
				</router-link> -->
        <router-link to="/uploadPlan">
          <el-card>
            <img src="../../assets/images/index/ico/shangchuan.png" />
            <div>策划案上传</div>
            <p>平台推荐、营销模式、活动 玩法、行业案例一应俱全</p>
          </el-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'index',
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
}
</script>
<style type="text/css" scoped lang="scss">
.box1 {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border-radius: 26px;
  padding: 30px 30px;
  margin-bottom: 20px;
}
.title {
  font-size: 30px;
  color: #333333;
  font-weight: 700;
  margin-bottom: 10px;
}
.card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .el-card {
    margin: 0 20px;
    width: 230px;
    height: 230px;
    box-sizing: border-box;
    img {
      width: auto;
      height: 70px;
    }
    div {
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    p {
      font-size: 16px;
      color: #333;
    }
  }
  .el-card:hover {
    transform: scale(1.1, 1.1);
  }
}
</style>
