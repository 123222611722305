<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/consult' }"
          style="font-size: 30px"
          replace
          >在线咨询</el-breadcrumb-item
        >
        <el-breadcrumb-item>图文咨询</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <el-form label-position="top">
        <el-form-item
          required
          :label="item.title"
          v-for="(item, i) in formData"
          :key="item.id"
        >
          <!-- 输入框3 -->
          <el-input
            v-model="item.value"
            style="width: 220px"
            v-if="item.field_type === 3"
            :placeholder="`请输入${item.title}`"
            @input="$forceUpdate()"
          ></el-input>
          <!-- 单选框 -->
          <el-radio-group v-model="item.value" v-if="item.field_type === 1">
            <el-radio
              :label="radioItem.key"
              v-for="(radioItem, radioIndex) in item.select_option"
              :key="radioIndex"
              @change="radioClick(radioItem.key, i)"
            >
              {{ radioItem.value }}
            </el-radio>
          </el-radio-group>
          <!-- 多选 -->
          <div v-if="item.field_type == 2">
            <el-checkbox
              :label="checkboxItem.value"
              v-for="(checkboxItem, checkboxIndex) in item.select_option"
              :key="checkboxIndex"
              v-model="checkboxItem.checked"
              @change="$forceUpdate()"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="预约时间" required>
          <!-- 时间选择 -->
          <el-date-picker
            v-model="time"
            type="datetime"
            placeholder="选择日期"
            @blur="$forceUpdate()"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          :label="`积分抵扣(账户剩余积分可抵扣${userInfo.score / 100}元)`"
          ><el-switch v-model="is_score"></el-switch
        ></el-form-item>
        <el-form-item
          :label="`余额抵扣:(账户剩余余额可抵扣${userInfo.money}'元)`"
          ><el-switch v-model="is_money"></el-switch
        ></el-form-item>
        <el-form-item label="应付金额"
          >￥{{ price > 0 ? price : 0 }}</el-form-item
        >
      </el-form>
    </div>
    <div class="but">
      <el-button>重置</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
    <!-- 支付二维码 -->
    <qrcode
      :codeShow.sync="codeShow"
      :qrcodeUrl="qrcodeUrl"
      @complete="completePay"
      @cancel="cancelPay"
    ></qrcode>
    <el-dialog
      title="提示"
      :visible.sync="show"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div>预约已提交，等待顾问确认！ 可在图文历史咨询中查看详情</div>
      <span slot="footer" class="dialog-footer">
        <el-button>图文历史咨询</el-button>
        <el-button type="primary" @click="$router.push('/consult')"
          >继续咨询</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import qrcode from '../../components/qrcode.vue'
export default {
  name: 'consult_form',
  data() {
    return {
      id: null,
      formData: new Array(),
      loading: false,
      is_score: false,
      is_money: false,
      time: null,
      show: false,
      codeShow: false,
      qrcodeUrl: null,
      image_consult_price: null,
      price: null,
      order: null,
    }
  },
  components: {
    qrcode,
  },
  computed: {
    ...mapState(['userInfo']),
    priceChange() {
      const { is_score, is_money } = this
      return { is_score, is_money }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.image_consult_price = this.$route.query.image_consult_price
      this.price = this.$route.query.image_consult_price
      this.getFromField()
    }
  },
  watch: {
    priceChange(e) {
      let data = parseFloat(this.image_consult_price).toFixed(2)
      let score = parseFloat(this.userInfo.score).toFixed(2) / 100
      let money = parseFloat(this.userInfo.money).toFixed(2)
      if (e.is_score && e.is_money) {
        this.price = parseFloat(data - score - money).toFixed(2)
      } else if (!e.is_score && e.is_money) {
        this.price = parseFloat(data - money).toFixed(2)
      } else if (e.is_score && !e.is_money) {
        this.price = parseFloat(data - score).toFixed(2)
      } else {
        this.price = parseFloat(data).toFixed(2)
      }
    },
  },
  methods: {
    ...mapActions(['keepUserInfo']),
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    // 选中任一radio时，由radio-group触发
    radioClick(key, index) {
      this.formData[index].value = key
			this.$forceUpdate()
    },
    // 获取表单
    getFromField() {
      this.loading = true
      this.$http.common
        .getFromField({
          type: 5,
        })
        .then((res) => {
          this.formData = res.data
          this.formData.forEach((item, i) => {
            item.value = ''
            item.field_id = item.id
            if (item.select_option) {
              item.select_option = JSON.parse(item.select_option)
              if (item.field_type == 2) {
                item.select_option.forEach((resItem, index) => {
                  item.select_option[index].checked = false
                })
              }
            }
          })
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true
      let data = new Array()
      this.formData.forEach((item, i) => {
        if (item.select_option && item.field_type == 2) {
          item.value = new Array()
          item.select_option.forEach((resItem, index) => {
            if (resItem.checked) {
              this.formData[i].value.push({
                value: resItem.value,
                key: resItem.key,
              })
            }
          })
        }
        data.push({
          field_id: item.field_id,
          value: item.value,
        })
      })
      let appointment_time
      try {
        appointment_time = this.$utils.getDate(this.time)
      } catch (error) {
        this.loading = false
        this.$message.error('请选择时间')
        return
      }
      this.$http.consult
        .submitImageOrder({
          id: this.id,
          is_score: this.is_score ? 1 : 0, //number	是	是否使用积分:1=是,0=否
          is_money: this.is_money ? 1 : 0, //number	是	是否使用余额:1=是,0=否
          data: [...data],
          from_id: this.formData[0].from_id,
          appointment_time: `${appointment_time.YYYY}-${appointment_time.MM}-${appointment_time.DD} ${appointment_time.h}-${appointment_time.m}`,
          method: 'scan',
        })
        .then((res) => {
          this.loading = false
          if (res.data.is_pay) {
            this.order = res.data.order
            this.qrcodeUrl = res.data.pay.qrcode
            this.codeShow = true
          } else {
            this.$message.success('积分/余额抵扣成功，无需支付。')
          }
          this.getuserinfo()
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 取消支付
    cancelPay() {
      this.$message.error('取消支付！')
      this.$router.go(-1)
    },
    // 完成支付
    completePay() {
      this.$http.consult
        .getImageOrderDetails({
          id: this.order.id,
        })
        .then((res) => {
          if (res.data.image_order.status.key === 1) {
            this.$message.error('未支付！')
            this.codeShow = true
          } else {
            this.$message.success('支付成功')
            this.show = true
          }
        })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}

.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #4086fc;
}

.content {
  padding: 15px 340px;
  overflow: auto;
  height: 670px;
}

.but {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid #4086fc;
}
</style>
