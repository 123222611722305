<template>
  <div>
    <el-container>
      <el-aside :width="isCollapse ? '135px' : '416px'">
        <navMenu :isCollapse="isCollapse" @collapse="collapse"></navMenu>
      </el-aside>
      <el-container>
        <el-header><headerMenu></headerMenu></el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <mobile :show="is_mobile_show" @submit="mobileSubmit"></mobile>
    <info :show="is_info_show" @submit="infoSubmit"></info>
    <infoBank :show="is_bank_info_show" @submit="infoBankSubmit"></infoBank>
  </div>
</template>

<script>
// @ is an alias to /src
import navMenu from '../../components/navMenu.vue'
import headerMenu from '../../components/headerMenu.vue'
import mobile from '../../components/info/mobile.vue'
import info from '../../components/info/info.vue'
import infoBank from '../../components/info/info_bank.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Home',
  components: {
    navMenu,
    headerMenu,
    mobile,
    info,
    infoBank,
  },
  data() {
    return {
      isCollapse: true,
      is_mobile_show: false,
      is_info_show: false,
      is_bank_info_show: false,
    }
  },
  created() {
    this.imLogin()
    this.getuserinfo()
  },
  mounted() {
    this.censorInfo()
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    ...mapActions(['keepUserInfo']),
    // 检查用户信息状态
    censorInfo() {
      if (this.userInfo.is_mobile == 0) {
        // 是否绑定手机号:0=未绑定,1=已绑定
        this.is_mobile_show = true
      } else if (this.userInfo.is_info == 0) {
        // 是否完善信息:0=未完善,1=已完善
        this.is_info_show = true
      } else if (this.userInfo.is_bank_info == 0) {
        // 是否完善银行信息:0=未完善,1=已完善
        this.is_bank_info_show = true
      }
    },
    mobileSubmit() {
      this.is_mobile_show = false
      this.getuserinfo()
      this.censorInfo()
    },
    infoSubmit() {
      this.is_info_show = false
      this.getuserinfo()
      this.censorInfo()
    },
    infoBankSubmit() {
      this.is_bank_info_show = false
      this.getuserinfo()
      this.censorInfo()
    },
    collapse() {
      this.isCollapse = !this.isCollapse
    },
    getuserinfo() {
      this.$http.user.getuserinfo().then((res) => {
        this.keepUserInfo(res.data)
      })
    },
    // 登录
    imLogin() {
      this.$http.user.getUserIm().then((res) => {
        this.$WebIM.conn.open({
          user: res.data.username,
          pwd: res.data.password,
          appKey: this.$WebIM.config.appkey,
        })
      })
    },
  },
}
</script>
<style type="text/css" scoped lang="scss">
.el-aside {
  transition: width 0.3s;
}
</style>
