<template>
	<div class="QRCode">
		<div id="login" class="login">
			<iframe class="iframe"></iframe>
			<p>班克慧销小程序扫一扫</p>
		</div>
	</div>
</template>
<!-- <script src="" type="text/javascript" charset="utf-8"></script> -->
<script>
import '../../utils/WxLogin.js'
export default {
	name: 'QRCode',
	data() {
		return {
			appid: 'wx9e7a4979ed127802',
			redirectUrl: '',
			scope: '',
			state: '',
		}
	},
	created() {
		this.getLoginParams()
	},
	methods: {
		getLoginParams() {
			this.$http.user.getLoginParams().then(res => {
				this.redirect_uri = res.data.redirect_uri
				this.scope = res.data.scope
				this.state = res.data.state
				this.wechatHandleClick()
			})
		},
		wechatHandleClick() {
			const appid = this.appid
			let obj = new WxLogin({
				id: 'login',
				appid: appid,
				scope: this.scope,
				state: this.state,
				redirect_uri: this.redirect_uri,
			})
		},
	},
}
</script>

<style scoped lang="scss">
.QRCode {
	width: 258px;
	height: 258px;
	margin: 0 auto;
	p {
		font-size: 22px;
		color: #000;
		margin-top: 20px;
		text-align: center;
	}
}
</style>
