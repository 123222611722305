<template>
	<div class="box" v-loading="loading">
		<div class="title">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="display: flex; align-items: center">
				<el-breadcrumb-item :to="{path: '/consult/consult_index'}" style="font-size: 30px" replace>在线咨询</el-breadcrumb-item>
				<el-breadcrumb-item>图文咨询订单</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="content">
			<div class="filter">
				<el-select v-model="status" placeholder="全部状态" size="small" style="margin: 0 10px 0 0">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				预约时间:
				<el-date-picker
					v-model="time"
					type="daterange"
					align="right"
					size="small"
					unlink-panels
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd"
					style="width: 230px; margin: 0 10px 0"
				></el-date-picker>
				<el-input style="width: 200px; margin: 0 10px" v-model="name" placeholder="请输入顾问名称" size="small"></el-input>
				<el-button type="primary" size="small" @click="getSearch">查询</el-button>
				<el-button size="small" @click="reset">重置</el-button>
				<el-button size="small" @click="getSearch">导出列表</el-button>
			</div>
			<div class="table">
				<el-table stripe :data="tableData.list" border size="mini" max-height="630" height="630">
					<el-table-column prop="id" label="序号" width="60" :resizable="false"></el-table-column>
					<el-table-column prop="name" label="顾问名称" :resizable="false" width="140"></el-table-column>
					<el-table-column prop="appointment_time" label="预约时间" :resizable="false" width="140"></el-table-column>
					<el-table-column prop="price" label="费用总额" :resizable="false"></el-table-column>
					<el-table-column prop="status.value" label="状态" :resizable="false"></el-table-column>
					<el-table-column label="操作" :resizable="false" width="250">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="detailsShow(scope.row)">查看详情</el-button>
							<el-button type="text" size="small" v-if="scope.row.status.key === 2 || scope.row.status.key === 3 || scope.row.status.key === 4" @click="refundShow(scope.row)">申请退款</el-button>
							<el-button type="text" size="small" v-if="scope.row.status.key === 3" @click="consult(scope.row)">开始咨询</el-button>
							<el-button type="text" size="small" v-if="scope.row.status.key === 4 || scope.row.status.key === 5 || scope.row.status.key === 7" @click="consult(scope.row)">咨询历史</el-button>

							<el-button type="text" size="small" v-if="scope.row.status.key === 4">咨询记录</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination
					style="text-align: right"
					background
					layout="prev, pager, next"
					:total="tableData.total_count"
					:page-size="pageSize"
					:current-page="current_page"
					@current-change="currentChange"
				></el-pagination>
			</div>
		</div>
		<!-- 退款弹框 -->
		<el-dialog title="申请退款" :visible.sync="refund_show" width="30%">
			<el-form label-width="100px">
				<el-form-item label="微信支付：">{{ refund_item.wechat_money }}元</el-form-item>
				<el-form-item label="积分支付：">{{ refund_item.score }}</el-form-item>
				<el-form-item label="余额支付：">{{ refund_item.money }}元</el-form-item>
				<el-form-item label="退款金额："><el-input v-model="refund_money"></el-input></el-form-item>
				<el-form-item label="退款原因："><el-input v-model="refund_remarks"></el-input></el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="refund_show = false">取 消</el-button>
				<el-button type="primary" @click="refund">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 详情弹框 -->
		<el-dialog title="音频咨询详情" :visible.sync="details_show" width="70%">
			<el-form label-width="100px">
				<el-form-item label="顾问名称">{{ details_item.name }}</el-form-item>
				<el-form-item label="预约时间">{{ details_item.appointment_time }}</el-form-item>
				<el-form-item label="费用总额">{{ details_item.price }}元</el-form-item>
				<el-form-item label="状态">{{ details_item.status.value }}</el-form-item>
				<el-form-item label="付款金额">{{ `${details_item.pay_money}元(微信支付:${details_item.wechat_money}元;余额支付:${details_item.money}元;积分抵扣:${details_item.score})` }}</el-form-item>
				<el-form-item label="退款备注：" v-if="details_item.status.key === 5 || details_item.status.key === 6 || details_item.status.key === 7">{{ details_item.refund_remarks }}</el-form-item>
				<el-form-item label="退款驳回：" v-if="details_item.status.key === 6">{{ details_item.reply_refund_remarks }}</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer"><el-button type="primary" @click="details_show = false">关闭</el-button></span>
		</el-dialog>
		<!-- 对话弹框 -->
		<el-dialog title="咨询" :visible.sync="imShow" width="50%" :before-close="handleClose" center style="padding: 0">
			<div class="im">
				<div class="im_heder">
					<el-avatar size="large" :src="details_item.adviser_user.avatar" icon="el-icon-user-solid"></el-avatar>
					<div>
						<div>{{ details_item.name }}</div>
						<div>预约时间：{{ details_item.appointment_time }}</div>
					</div>
					<div>在线时间：9:00-18:00</div>
				</div>
				<div class="im_box" id="im_box">
					<div class="load text-center" :style="load !== '点击加载更多' ? '' : 'text-decoration: underline; cursor: pointer'" @click="getHistoryMsg">{{ load }}</div>
					<div v-for="(item, index) in im_list" :key="index" :id="`msg-${item.id}`" v-if="item.customEvent !== 'onAudioMessage'">
						<!-- 收L -->
						<div class="receive" v-if="im_item.username == item.to">
							<div class="avatar text-center">
								<el-avatar size="large" :src="details_item.adviser_user.avatar" icon="el-icon-user-solid"></el-avatar>
								<div class="name">{{ details_item.adviser_user.nickname }}</div>
							</div>
							<div>
								<div class="time">{{ formatDate(item.time) }}</div>
								<div class="news" v-if="item.contentsType == 'TEXT'">{{ item.data }}</div>
								<div class="news" v-if="item.type == 'TEXT'">{{ item.msg }}</div>
								<div class="news" v-if="item.contentsType == 'IMAGE'"><el-image style="width: 100px; height: 100px" :src="item.url" :preview-src-list="[item.url]" /></div>
							</div>
						</div>
						<!-- 发R -->
						<div class="send" v-else-if="im_item.username != item.to">
							<div class="text-right">
								<div class="time">{{ formatDate(item.time) }}</div>
								<div class="news" v-if="item.contentsType == 'TEXT'">{{ item.data }}</div>
								<div class="news" v-if="item.type == 'txt'">{{ item.msg }}</div>
								<div class="news" v-if="item.contentsType == 'IMAGE'"><el-image style="width: 100px; height: 100px" :src="item.url" :preview-src-list="[item.url]" /></div>
							</div>
							<div class="avatar text-center">
								<el-avatar size="large" :src="details_item.order_user.avatar" icon="el-icon-user-solid"></el-avatar>
								<div class="name">{{ details_item.order_user.nickname }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="im_input" v-if="details_item.status.key !== 3">
					<div class="tool">
						<el-upload class="upload-demo" :action="uploadURL" :headers="uploadHeaders" :on-success="handleAvatarSuccess" :multiple="false" :show-file-list="false" accept="image/*">
							<div class="picture"><i class="el-icon-picture"></i></div>
						</el-upload>
					</div>
					<div class="im_input_box">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea" resize="none" :autosize="{minRows: 5, maxRows: 5}" />
						<el-button type="primary" class="submit" @click="submit">发送</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import configure from '../../utils/configure.js'
export default {
	data() {
		return {
			uploadURL: configure.uploadURL,
			options: [
				{
					value: 0,
					label: '全部',
				},
				{
					value: 2,
					label: '已预约',
				},
				{
					value: 3,
					label: '咨询中',
				},
				{
					value: 4,
					label: '已完成',
				},
				{
					value: 5,
					label: '退款',
				},
			],
			loading: false,
			status: 0,
			current_page: 1,
			pageSize: 15,
			name: null,
			time: new Array(),
			search: null,
			tableData: {
				list: new Array(),
			},
			refund_item: new Object(),
			refund_show: false,
			refund_money: null, //退款金额
			refund_remarks: null, //退款备注
			details_show: false,
			details_item: new Object(),
			imShow: false,
			textarea: '',
			im_item: new Object(),
			im_list: new Array(),
			that: this,
			uploadHeaders: {
				token: localStorage.getItem('token'),
			},
			load: '点击加载更多',
		}
	},
	watch: {
		status() {
			this.current_page = 1
		},
	},
	created() {
		this.enterSubmit()
		this.getImageOrderList()
		this.details_item.status = new Object()
		this.details_item.order_user = new Object()
		this.details_item.adviser_user = new Object()
		//监听消息
		this.$WebIM.conn.listen({
			//收到文本消息
			onTextMessage: message => {
				this.im_list.push(message)
				this.scroll()
			},
			//收到图片消息
			onPictureMessage: message => {
				this.im_list.push(message)
				this.scroll()
			},
		})
	},
	computed: {
		formatDate() {
			return function(e) {
				let a = e / 1000
				let obj = this.$utils.formatDate(a)
				let time = `${obj.Y}-${obj.M}-${obj.D} ${obj.h}:${obj.m}:${obj.s}`
				return time
			}
		},
	},
	methods: {
		getImageOrderList() {
			this.loading = true
			this.$http.consult
				.getImageOrderList({
					status: this.status,
					current_page: this.current_page,
					name: this.name,
					page_size: this.pageSize,
					start_time: this.time[0],
					end_time: this.time[1],
				})
				.then(res => {
					this.tableData = res.data
					this.loading = false
				})
				.catch(res => {
					this.loading = false
				})
		},
		// 分页
		currentChange(e) {
			this.current_page = e
			this.getImageOrderList()
		},
		// 查询
		getSearch() {
			this.current_page = 1
			this.getImageOrderList()
		},
		// 重置
		reset() {
			this.name
			this.status = 0
			this.current_page = 1
			this.time = new Array()
			this.getImageOrderList()
		},
		detailsShow(e) {
			this.details(e, () => {
				this.details_show = true
			})
		},
		// 查看详情
		details(e, callback = () => {}) {
			this.$http.consult
				.getImageOrderDetails({
					id: e.id,
				})
				.then(res => {
					this.details_item = res.data.image_order
					this.details_item.name = res.data.adviser_user.nickname
					this.details_item.order_user = res.data.order_user
					this.details_item.adviser_user = res.data.adviser_user
					callback()
					console.log(this.details_item)
				})
		},
		//申请退款弹框
		refundShow(e) {
			this.refund_show = true
			this.refund_item = e
			this.refund_money = null //退款金额
			this.refund_remarks = null //退款备注
		},
		// 申请退款
		refund() {
			this.$http.consult
				.applyImageRefund({
					id: this.refund_item.id,
					refund_money: this.refund_money,
					refund_remarks: this.refund_remarks,
				})
				.then(res => {
					this.refund_show = false
					this.$message.success('申请成功')
				})
		},
		//开始咨询
		consult(e) {
			this.$http.user
				.getUserIm({
					id: e.id,
				})
				.then(res => {
					this.details(e, () => {
						this.im_item = res.data
						this.getHistoryMsg()
					})
				})
		},
		// 获取历史消息
		getHistoryMsg() {
			this.load = '加载中...'
			this.$WebIM.conn.fetchHistoryMessages({
				queue: this.im_item.to_username, //需特别注意queue属性值为大小写字母混合，以及纯大写字母，会导致拉取漫游为空数组，因此注意将属性值装换为纯小写
				isGroup: false,
				count: 10,
				success: res => {
					if (res.length != 0) {
						if (this.im_list.length === 0) this.scroll()
						this.im_list = [...res, ...this.im_list]
						this.load = '点击加载更多'
					} else {
						this.load = '无更多历史记录'
					}
					this.imShow = true
				},
			})
		},
		// 关闭对话
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then(_ => {
					this.$WebIM.conn.mr_cache = new Array()
					this.im_list = new Array()
					this.textarea = ''
					done()
				})
				.catch(_ => {})
		},
		// 发送消息
		submit() {
			if (!this.textarea) {
				this.$message.error('输入内容不能为空。')
			} else {
				let id = this.$WebIM.conn.getUniqueId() // 生成本地消息id
				let msg = new this.$WebIM.message('txt', id) // 创建文本消息
				msg.set({
					msg: this.textarea, // 消息内容
					to: this.im_item.to_username, // 接收消息对象（用户id）
					chatType: 'singleChat', // 设置为单聊
					success: message => {
						this.im_list.push(msg.body)
						this.textarea = ''
						this.scroll()
					},
				})
				this.$WebIM.conn.send(msg.body)
			}
		},
		//发送图片成功
		handleAvatarSuccess(e) {
			let id = this.$WebIM.conn.getUniqueId() // 生成本地消息id
			let msg = new this.$WebIM.message('img', id) // 创建图片消息
			msg.set({
				body: {
					type: 'file',
					url: e.data.fullurl,
				},
				to: this.im_item.to_username, // 接收消息对象
				success: res => {
					this.im_list.push({
						contentsType: 'IMAGE',
						id: id,
						to: this.im_item.to_username,
						type: 'chat',
						url: e.data.fullurl,
						time: Date.parse(new Date()),
					})
					this.scroll()
				},
			})
			this.$WebIM.conn.send(msg.body)
		},
		// 聊天框滚动到底部
		scroll() {
			setTimeout(() => {
				let div = document.querySelector('#im_box')
				div.scrollTop = 999999999999999999
			}, 1000)
		},
		enterSubmit() {
			document.onkeydown = e => {
				//13表示回车键，baseURI是当前页面的地址，为了更严谨，也可以加别的，可以打印e看一下
				if (e.keyCode === 13 && this.imShow) {
					//回车后执行搜索方法
					this.submit()
				}
			}
		},
	},
}
</script>

<style type="text/css" scoped lang="scss">
.box {
	box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
	width: 100%;
	height: 100%;
	border-radius: 26px;
}
.title {
	font-size: 30px;
	color: #333;
	font-weight: 700;
	padding: 15px 60px;
	border-bottom: 1px solid #4086fc;
}

.content {
	padding: 15px 70px;
}
.table {
	margin-top: 10px;
}
.im {
	position: relative;
	width: calc(100% + 50px);
	margin-left: -25px;
	margin-top: -25px;
	&_heder {
		box-shadow: 0px 5px 5px 0px rgba(75, 75, 75, 0.09);
		padding: 10px 24px;
		display: flex;
		align-items: center;
		> div:nth-child(2) {
			flex: 1;
			margin-left: 22px;
			> div:nth-child(1) {
				font-weight: 700;
				font-size: 18px;
				color: #333333;
			}
			> div:nth-child(2) {
				font-size: 18px;
				color: #9b9b9b;
			}
		}
		> div:nth-child(3) {
			color: #9b9b9b;
			font-size: 18px;
		}
	}
	.load {
		color: #9b9b9b;
		font-size: 16px;
	}
	&_box {
		height: 400px;
		padding: 7px 40px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: scroll;
		.receive {
			display: flex;
			margin-bottom: 10px;
			align-items: center;
			.avatar {
				margin-right: 30px;
				.name {
					font-size: 12px;
					color: #9b9b9b;
				}
			}
			.time {
				font-size: 12px;
				color: #9b9b9b;
			}
			.news {
				max-width: 600px;
				background-color: #f6f6f6;
				padding: 10px 15px;
				font-size: 18px;
				color: #000;
				border-radius: 4px;
				margin-top: 5px;
			}
		}
		.send {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;
			align-items: center;
			.avatar {
				margin-left: 30px;
				.name {
					font-size: 12px;
					color: #9b9b9b;
				}
			}
			.time {
				font-size: 12px;
				color: #9b9b9b;
			}
			.news {
				max-width: 600px;
				background-color: #ecf2fe;
				padding: 10px 15px;
				font-size: 18px;
				color: #000;
				border-radius: 4px;
				margin-top: 5px;
			}
		}
	}
	&_box::-webkit-scrollbar {
		display: none;
	}
	&_input {
		border-radius: 8px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
		margin-bottom: -30px;
		position: relative;
		z-index: 1;
		.tool {
			background-color: #f6f6f6;
			padding: 0px 0;
			.picture {
				font-size: 30px;
				margin: 0 0 0 15px;
			}
		}
		&_box {
			position: relative;
			.submit {
				position: absolute;
				right: 20px;
				bottom: 20px;
			}
		}
	}
}
</style>
