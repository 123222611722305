import axios from '../../utils/axios.js'

let calendar = {
	// 获取营销日历列表
	getcalendarlist(data) {
		return axios({
			url: '/api/calendar/getcalendarlist',
			method: 'get',
			data: data
		})
	},
	// 获取营销日历详情
	getcalendardetails(data) {
		return axios({
			url: '/api/calendar/getcalendardetails',
			method: 'get',
			data: data
		})
	},
}

export default calendar
