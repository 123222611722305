import axios from '../../utils/axios.js'

let mall = {
  // 商品分类
  getShopCategory(data) {
    return axios({
      url: '/api/shop/getShopCategory',
      method: 'get',
      data: data,
    })
  },
  // 获取商品列表
  getGoodsList(data) {
    return axios({
      url: '/api/shop/getGoodsList',
      method: 'get',
      data: data,
    })
  },
  // 获取商品详情
  getGoodsDetails(data) {
    return axios({
      url: '/api/shop/getGoodsDetails',
      method: 'get',
      data: data,
    })
  },
  // 获取猜你喜欢商品列表
  getLikeGoodsLost(data) {
    return axios({
      url: '/api/shop/getLikeGoodsLost',
      method: 'get',
      data: data,
    })
  },
  // 获取我的收货地址列表
  getUserAddressList(data) {
    return axios({
      url: '/api/shop/getUserAddressList',
      method: 'get',
      data: data,
    })
  },
  // 获取我的收货地址详情
  getUserAddressDetails(data) {
    return axios({
      url: '/api/shop/getUserAddressDetails',
      method: 'get',
      data: data,
    })
  },
  // 添加收货地址
  addAddress(data) {
    return axios({
      url: '/api/shop/addAddress',
      method: 'post',
      data: data,
    })
  },
  //  礼品采购下单
  submitOrder(data) {
    return axios({
      url: '/api/shop_order/submitOrder',
      method: 'post',
      data: data,
    })
  },
  // 获取订单详情
  getOrderDetails(data) {
    return axios({
      url: '/api/shop_order/getOrderDetails',
      method: 'get',
      data: data,
    })
  },
  // 获取地址列表
  getAddrList(data) {
    return axios({
      url: '/api/common/getAddrList',
      method: 'get',
      data: data,
    })
  },
  // 获取街道列表 
  getStreetList(data) {
    return axios({
      url: '/api/common/getStreetList',
      method: 'get',
      data: data,
    })
  },
  // 获取礼品采购订单列表
  getOrderList(data) {
    return axios({
      url: '/api/shop_order/getOrderList',
      method: 'get',
      data: data,
    })
  },
  // 获取待支付订单支付信息 
  getOrderPay(data) {
    return axios({
      url: '/api/shop_order/getOrderPay',
      method: 'get',
      data: data,
    })
  },
  // 申请退款 
  shopRefund(data) {
    return axios({
      url: '/api/shop_order/shopRefund',
      method: 'post',
      data: data,
    })
  },
}

export default mall
