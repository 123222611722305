<template>
  <div class="box" v-loading="loading">
    <div class="title">礼品采购</div>
    <div class="content">
      <el-empty
        description="数据为空"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        "
        v-if="tableData.list.length === 0"
      ></el-empty>
      <router-link to="/mall/mall_order">
        <el-button class="order" type="warning" size="small">
          查看礼品订单
        </el-button>
      </router-link>
      <el-input
        style="width: 300px"
        v-model="search"
        placeholder="请输入关键字或商品名称"
        size="small"
        color="#FDB75E"
      ></el-input>
      <el-button type="warning" size="small" @click="getGoodsList">
        查询
      </el-button>
      <div class="content_box">
        <div class="label">
          <el-menu
            class="category"
            active-text-color="#FDB75E"
            @select="select"
          >
            <el-menu-item index="">全部</el-menu-item>
            <el-submenu
              :index="`${item.value}`"
              v-for="item in categoryList"
              :key="item.value"
            >
              <template slot="title">
                <span>{{ item.label }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="`${item.value}-${resItem.value}`"
                  v-for="resItem in item.children"
                  :key="resItem.value"
                  >{{ resItem.label }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="list">
          <div
            class="list_li"
            v-for="item in tableData.list"
            :key="item.id"
            @click="details(item)"
          >
            <div class="list_li-img">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.main_image"
                fit="cover"
              >
              </el-image>
            </div>
            <div class="list_li-text">{{ item.title }}</div>
            <div class="list_li-price">￥{{ item.price }}</div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        v-if="tableData.list.length > 0"
        style="text-align: right"
        background
        layout="prev, pager, next"
        :total="tableData.total_count"
        :page-size="pageSize"
        :current-page="current_page"
        @current-change="currentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plan_my',
  data() {
    return {
      search: '',
      current_page: 1,
      pageSize: 8,
      tableData: {
        list: new Array(),
      },
      loading: false,
      categoryList: new Array(),
      shop_category_id: '',
      shop_category_id2: '',
      activity_id: '',
    }
  },
  computed: {},

  created() {
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id
    }
    this.getShopCategory()
    this.getGoodsList()
  },
  methods: {
    // 分页
    currentChange(e) {
      this.current_page = e
      this.getGoodsList()
    },
    // 获取分类
    getShopCategory() {
      this.$http.mall.getShopCategory().then((res) => {
        this.categoryList = res.data
      })
    },
    select(e) {
      if (e) {
        let data = e.split('-')
        this.shop_category_id = data[0]
        this.shop_category_id2 = data[1]
      } else {
        this.shop_category_id = ''
        this.shop_category_id2 = ''
      }
      this.getGoodsList()
    },
    // 获取商品列表
    getGoodsList() {
      this.loading = true
      this.$http.mall
        .getGoodsList({
          search: this.search,
          shop_category_id: this.shop_category_id,
          shop_category_id2: this.shop_category_id2,
          current_page: this.current_page,
          page_size: this.pageSize,
          activity_id: this.activity_id,
        })
        .then((res) => {
          this.tableData = res.data
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 商品详情
    details(e) {
      this.$router.push({
        path: '/mall/mall_details',
        query: {
          id: e.id,
        },
      })
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 70px;
  border-bottom: 1px solid #fd8927;
}

.content {
  position: relative;
  padding: 15px 30px;
  height: 730px;
  .order {
    margin: 0 48px;
  }
  .el-input {
    margin-right: 20px;
  }

  &_box {
    display: flex;
    .label {
      min-width: 200px;
      .category {
        height: 710px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: scroll;
      }
      ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .list {
      padding: 10px 30px 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      min-width: 1400px;
      &_li {
        width: 330px;
        height: 316px;
        box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);
        padding: 10px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-img {
          margin: 0 auto;
          height: 215px;
        }
        &-text {
          font-size: 18px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &-price {
          color: #e55623;
          font-size: 18px;
        }
      }
      &_li:hover {
        transform: scale(1.1);
        transition: transform 1s;
        cursor: pointer;
      }
    }
  }
  .el-pagination {
    position: absolute;
    right: 55px;
    bottom: 5px;
  }
}
</style>
<style scoped>
.content >>> .el-input__inner {
  border: 1px solid #fdb75e;
}
.content >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fdb75e;
  color: #fff;
}
.content >>> .el-pagination.is-background .el-pager li:hover {
  color: #fdb75e;
}
</style>
