import axios from '../../utils/axios.js'

let consult = {
  // 获取顾问列表
  getConsultList(data) {
    return axios({
      url: '/api/consult/getConsultList',
      method: 'get',
      data: data,
    })
  },
  // 获取顾问详情
  getConsultDetails(data) {
    return axios({
      url: '/api/consult/getConsultDetails',
      method: 'get',
      data: data,
    })
  },
  //音频咨询下单
  submitVideoOrder(data) {
    return axios({
      url: '/api/Consult/submitVideoOrder',
      method: 'post',
      data: data,
    })
  },
  // 图文咨询下单
  submitImageOrder(data) {
    return axios({
      url: '/api/Consult/submitImageOrder',
      method: 'post',
      data: data,
    })
  },
  // 获取图文咨询订单详情
  getImageOrderDetails(data) {
    return axios({
      url: '/api/Consult/getImageOrderDetails',
      method: 'get',
      data: data,
    })
  },
  // 获取音频咨询订单详情
  getVideoOrderDetails(data) {
    return axios({
      url: '/api/Consult/getVideoOrderDetails',
      method: 'get',
      data: data,
    })
  },
  // 获取历史音频咨询订单列表
  getVideoOrderList(data) {
    return axios({
      url: '/api/consult/getVideoOrderList',
      method: 'get',
      data: data,
    })
  },
  // 获取历史图文咨询订单列表
  getImageOrderList(data) {
    return axios({
      url: '/api/consult/getImageOrderList',
      method: 'get',
      data: data,
    })
  },
  // 音频咨询申请退款
  applyVideoRefund(data) {
    return axios({
      url: '/api/consult/applyVideoRefund',
      method: 'post',
      data: data,
    })
  },
  // 图文咨询申请退款
  applyImageRefund(data) {
    return axios({
      url: '/api/consult/applyImageRefund',
      method: 'post',
      data: data,
    })
  },
  // 获取视频token 
  getUserVideo(data) {
    return axios({
      url: '/api/Im/getUserVideo',
      method: 'get',
      data: data,
    })
  },
}

export default consult
