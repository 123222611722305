import axios from '../../utils/axios.js'

const plan = {
  // 获取我的策划案列表（改）
  getMyCasesList(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getMyCasesList',
      data: data,
    })
  },
  // 策划案下单（整文）
  payorder(data) {
    return axios({
      method: 'post',
      url: '/api/cases/payorder',
      data: data,
    })
  },
  // 支付新方案订单（智能生成）
  payNewCasesOrder(data) {
    return axios({
      method: 'post',
      url: '/api/cases/payNewCasesOrder',
      data: data,
    })
  },
  //  获取我的策划案详情
  getMyCasesDetails(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getMyCasesDetails',
      data: data,
    })
  },
  // 提交新方案（改）
  submitNewCases(data) {
    return axios({
      method: 'post',
      url: '/api/cases/submitNewCases',
      data: data,
    })
  },
  // 获取重新生成方案段落列表
  getParagraphList(data) {
    return axios({
      method: 'post',
      url: '/api/cases/getParagraphList',
      data: data,
    })
  },
  // 支付新方案订单（智能生成）
  payNewCasesOrder(data) {
    return axios({
      method: 'post',
      url: '/api/cases/payNewCasesOrder',
      data: data,
    })
  },
  // 获取我的深度定制策划案列表
  getMyCustomizedCasesList(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getMyCustomizedCasesList',
      data: data,
    })
  },
  // 获取我的深度定制策划案详情
  getMyCustomizedCasesDetails(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getMyCustomizedCasesDetails',
      data: data,
    })
  },
  // 确认深度定制策划案
  confirmCustomizedCases(data) {
    return axios({
      method: 'post',
      url: '/api/cases/getMyCustomizedCasesDetails',
      data: data,
    })
  },
  // 深度定制申请退款
  applyCustomizedCasesRefund(data) {
    return axios({
      method: 'post',
      url: '/api/cases/applyCustomizedCasesRefund',
      data: data,
    })
  },
  // 获取深度定制金额
  getCustomizedMoney() {
    return axios({
      method: 'get',
      url: '/api/cases/getCustomizedMoney',
    })
  },
  // 提交深度定制策划案
  submitCustomizedCases(data) {
    return axios({
      method: 'post',
      url: '/api/cases/submitCustomizedCases',
      data: data,
    })
  },
  // 获取分类列表
  getcasescategorylist(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getcasescategorylist',
      data: data,
    })
  },
  // 上传策划案
  uploadCase(data) {
    return axios({
      method: 'post',
      url: '/api/cases_user/uploadCase',
      data: data,
    })
  },
  // 获取我的策划案列表
  getcaseslist(data) {
    return axios({
      method: 'get',
      url: '/api/cases_user/getcaseslist',
      data: data,
    })
  },
  // 获取我的策划案详情
  getCaseDetails(data) {
    return axios({
      method: 'get',
      url: '/api/cases_user/getCaseDetails',
      data: data,
    })
  },
  // 编辑我的策划案（未上线都可修改）
  updateCase(data) {
    return axios({
      method: 'post',
      url: '/api/cases_user/updateCase',
      data: data,
    })
  },
  // 申请下线
  applyDownOnline(data) {
    return axios({
      method: 'post',
      url: '/api/cases_user/applyDownOnline',
      data: data,
    })
  },
  	// 获取替换段落列表 
	getCaseParagraphList(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getCaseParagraphList',
      data: data,
    })
	},
  	// 替换段落 
	updateParagraph(data) {
    return axios({
      method: 'post',
      url: '/api/cases/updateParagraph',
      data: data,
    })
	},
}
export default plan
