<template>
  <div class="account">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="0"
      class="demo-ruleForm"
    >
      <el-form-item prop="mobile">
        <el-input
          v-model="ruleForm.mobile"
          placeholder="账号/手机号"
        ></el-input>
      </el-form-item>
      <!-- 验证码登录 -->
      <el-form-item prop="captcha" v-if="type === 'verification'">
        <el-input v-model="ruleForm.captcha" placeholder="验证码"></el-input>
        <el-button
          type="primary"
          class="getVerificationBut"
          :loading="captchaLoading"
          size="mini"
          round
          @click="getCaptcha"
        >
          获取验证码
        </el-button>
      </el-form-item>
      <!-- 密码登录 -->
      <el-form-item prop="password" v-else-if="type === 'password'">
        <el-input
          v-model="ruleForm.password"
          placeholder="密码"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item class="operate text-right">
        <span v-if="type === 'verification'" @click="type = 'password'">
          密码登录
        </span>
        <span v-else-if="type === 'password'" @click="type = 'verification'">
          验证码登录
        </span>
        <!-- <span>忘记密码?</span> -->
      </el-form-item>
      <el-form-item class="protocol text-center">
        <el-checkbox v-model="checked" />
        我已经同意并接收
        <span>《用户协议》</span>
        和
        <span>《隐私协议》</span>
      </el-form-item>
      <el-form-item class="text-center">
        <el-button
          class="login"
          type="primary"
          :loading="loading"
          @click="submitForm('ruleForm')"
        >
          登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'account',
  props: {},
  data() {
    return {
      type: 'verification', //password
      loading: false,
      checked: false,
      captchaLoading: false,
      ruleForm: {
        mobile: '',
        password: '',
        captcha: '',
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        captcha: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    ...mapActions(['keepUserInfo']),
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (!valid) return false
        if (!this.checked) {
          this.$message.error(
            '请勾选“我已经同意并接收《用户协议》和《隐私协议》”'
          )
          return
        }
        let data = {}
        this.loading = true
        if (this.type === 'password') {
          // 密码登录
          data.mobile = this.ruleForm.mobile
          data.password = this.ruleForm.password
          this.$http.user.login(data).then((res) => {
            this.keepUserInfo(res.data.userinfo)
            this.$localStorage.setItem('token', res.data.userinfo.token)
            this.$message.success('登录成功')
            this.$router.replace('/home/index')
          })
        } else if (this.type === 'verification') {
          //验证码登录
          data.mobile = this.ruleForm.mobile
          data.captcha = this.ruleForm.captcha
          this.$http.user.mobilelogin(data).then((res) => {
            this.keepUserInfo(res.data.userinfo)
            this.$localStorage.setItem('token', res.data.userinfo.token)
            this.$message.success('登录成功')
            this.$router.replace('/home/index')
          })
        }
        this.loading = false
      })
    },
    // 获取验证码
    getCaptcha() {
      if (this.ruleForm.mobile) {
        this.captchaLoading = true
        this.$http.user
          .mobileSend({
            mobile: this.ruleForm.mobile,
            event: 'mobilelogin',
          })
          .then((res) => {
            console.log(res)
            this.$message.success('验证码发送成功。')
            this.captchaLoading = false
          })
          .catch((res) => {
            this.captchaLoading = false
          })
      } else {
        this.$message.error('请输入正确的手机号。')
      }
    },
  },
}
</script>

<style scoped>
.account {
  padding: 0 147px;
}
.account >>> .el-input__inner {
  height: 80px;
  border-radius: 43px;
  background-color: #f5f5f5;
}

.operate span {
  margin: 10px;
  color: #509dec;
  font-size: 20px;
  cursor: pointer;
}
.protocol span {
  color: #509dec;
  cursor: pointer;
}
.login {
  width: 100%;
  height: 88px;
  border-radius: 43px;
  font-size: 34px;
}
.getVerificationBut {
  position: absolute !important;
  right: 10px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
</style>
