<template>
  <div class="box" v-loading="loading">
    <div class="title">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="display: flex; align-items: center"
      >
        <el-breadcrumb-item
          :to="{ path: '/consult/consult_index' }"
          style="font-size: 30px"
          replace
        >
          在线咨询
        </el-breadcrumb-item>
        <el-breadcrumb-item>音频咨询订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="filter">
        <el-select
          v-model="status"
          placeholder="全部状态"
          size="small"
          style="margin: 0 10px 0 0"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        预约时间:
        <el-date-picker
          v-model="time"
          type="daterange"
          align="right"
          size="small"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="width: 230px; margin: 0 10px 0"
        ></el-date-picker>
        <el-input
          style="width: 200px; margin: 0 10px"
          v-model="name"
          placeholder="请输入顾问名称"
          size="small"
        ></el-input>
        <el-button type="primary" size="small" @click="getSearch">
          查询
        </el-button>
        <el-button size="small" @click="reset">重置</el-button>
        <el-button size="small" @click="getSearch"> 导出列表 </el-button>
      </div>
      <div class="table">
        <el-table
          stripe
          :data="tableData.list"
          border
          size="mini"
          max-height="630"
          height="630"
        >
          <el-table-column
            prop="id"
            label="序号"
            width="60"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="appointment_time"
            label="预约时间"
            :resizable="false"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="顾问名称"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="start_time"
            label="音频开始时间"
            :resizable="false"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="end_time"
            label="音频结束时间"
            :resizable="false"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="duration"
            label="时长(min)"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="费用总额(￥)"
            :resizable="false"
          ></el-table-column>
          <el-table-column
            prop="status.value"
            label="状态"
            :resizable="false"
          ></el-table-column>
          <el-table-column label="操作" :resizable="false" width="250">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="details(scope.row)">
                查看详情
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="
                  scope.row.status.key === 2 ||
                  scope.row.status.key === 3 ||
                  scope.row.status.key === 4
                "
                @click="refundShow(scope.row)"
              >
                申请退款
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="scope.row.status.key === 2 || scope.row.status.key === 3"
                @click="call(scope.row)"
              >
                发起音频
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="text-align: right"
          background
          layout="prev, pager, next"
          :total="tableData.total_count"
          :page-size="pageSize"
          :current-page="current_page"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 退款弹框 -->
    <el-dialog title="申请退款" :visible.sync="refund_show" width="30%">
      <el-form label-width="100px">
        <el-form-item label="微信支付："
          >{{ refund_item.wechat_money }}元
        </el-form-item>
        <el-form-item label="积分支付：">{{ refund_item.score }}</el-form-item>
        <el-form-item label="余额支付："
          >{{ refund_item.money }}元</el-form-item
        >
        <el-form-item label="退款金额：">
          <el-input v-model="refund_money"></el-input>
        </el-form-item>
        <el-form-item label="退款原因：">
          <el-input v-model="refund_remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refund_show = false">取 消</el-button>
        <el-button type="primary" @click="refund">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 详情弹框 -->
    <el-dialog title="音频咨询详情" :visible.sync="details_show" width="70%">
      <el-form label-width="100px">
        <el-form-item label="顾问名称">{{ details_item.name }} </el-form-item>
        <el-form-item label="预约时间"
          >{{ details_item.start_time }}-{{
            details_item.end_time
          }}</el-form-item
        >
        <el-form-item label="费用总额">{{ details_item.price }}元</el-form-item>
        <el-form-item label="状态">{{
          details_item.status.value
        }}</el-form-item>
        <el-form-item label="付款金额">
          {{
            `${details_item.pay_money}元;(微信支付:${details_item.wechat_money}元;余额支付:${details_item.money}积分抵扣:${details_item.score})`
          }}
        </el-form-item>
        <el-form-item label="音频时长">{{
          details_item.duration
        }}</el-form-item>
        <el-form-item
          label="退款备注："
          v-if="
            details_item.status.key === 5 ||
            details_item.status.key === 6 ||
            details_item.status.key === 7
          "
          >{{ details_item.refund_remarks }}</el-form-item
        >
        <el-form-item label="退款驳回：" v-if="details_item.status.key === 6">{{
          details_item.reply_refund_remarks
        }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="details_show = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 视频通话 -->
    <el-dialog
      title="视频通话"
      :visible.sync="audioShow"
      fullscreen
      :before-close="handleClose"
    >
    </el-dialog>
  </div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
let rtc = {
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
}
rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

export default {
  data() {
    return {
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 2,
          label: '已预约',
        },
        {
          value: 3,
          label: '咨询中',
        },
        {
          value: 4,
          label: '已完成',
        },
        {
          value: 5,
          label: '退款',
        },
      ],
      loading: false,
      status: 0,
      current_page: 1,
      pageSize: 15,
      name: null,
      time: new Array(),
      search: null,
      tableData: {
        list: new Array(),
      },
      refund_item: new Object(),
      refund_show: false,
      refund_money: null, //退款金额
      refund_remarks: null, //退款备注
      details_show: false,
      details_item: new Object(),
      audioShow: false,
      appId: '87d8dbf2617f44d69e84bac99469ce1b',
    }
  },
  watch: {
    status() {
      this.current_page = 1
    },
  },
  created() {
    this.getVideoOrderList()
    this.details_item.status = new Object()
  },
  methods: {
    getVideoOrderList() {
      this.loading = true
      this.$http.consult
        .getVideoOrderList({
          status: this.status,
          current_page: this.current_page,
          name: this.name,
          page_size: this.pageSize,
          start_time: this.time[0],
          end_time: this.time[1],
        })
        .then((res) => {
          this.tableData = res.data
          this.loading = false
        })
        .catch((res) => {
          this.loading = false
        })
    },
    // 分页
    currentChange(e) {
      this.current_page = e
      this.getVideoOrderList()
    },
    // 查询
    getSearch() {
      this.current_page = 1
      this.getVideoOrderList()
    },
    // 重置
    reset() {
      this.name
      this.status = 0
      this.current_page = 1
      this.time = new Array()
      this.getVideoOrderList()
    },
    // 查看详情
    details(e) {
      this.$http.consult
        .getVideoOrderDetails({
          id: e.id,
        })
        .then((res) => {
          console.log(res)
          this.details_show = true
          this.details_item = res.data.video_order
          this.details_item.name = res.data.adviser_user.nickname
        })
    },
    //申请退款弹框
    refundShow(e) {
      this.refund_show = true
      this.refund_item = e
      this.refund_money = null //退款金额
      this.refund_remarks = null //退款备注
    },
    // 申请退款
    refund() {
      this.$http.consult
        .applyVideoRefund({
          id: this.refund_item.id,
          refund_money: this.refund_money,
          refund_remarks: this.refund_remarks,
        })
        .then((res) => {
          this.refund_show = false
          this.$message.success('申请成功')
        })
    },
    //发起音频
    call(e) {
      this.$message.warning('请前往小程序，发起视频。')
      // console.log(e)
      // this.$http.consult
      //   .getUserVideo({
      //     id: e.id,
      //   })
      //   .then((res) => {
      //     let obj = {
      //       token: res.data.token,
      //       uid: res.data.user_id,
      //       channel: res.data.channelName,
      //     }
      //     this.audioShow = true
      //     this.subscribeEvents()
      //     this.join(...obj)
      //     console.log(res)
      //   })
    },
    // 注册事件
    subscribeEvents() {
      // 该回调通知远端用户发布了新的音频轨道或者视频轨道。
      rtc.client.on('user-published', async (user, mediaType) => {
        console.log('远端用户发布了新的音频轨道或者视频轨道')
        // 订阅远端用户的音视频轨道。
        await rtc.client.subscribe(user, mediaType)
        // If the remote user publishes a video track.
        if (mediaType === 'video') {
        }
        if (mediaType === 'audio') {
        }
        //该回调通知远端用户取消发布了音频或视频轨道。
        rtc.client.on('user-unpublished', (user) => {
          console.log('远端用户取消发布了音频或视频轨道')
        })
      })
    },
    async join(token, channel, uid) {
      // 加入房间
      await rtc.client.join(this.appId, channel, token, uid)
      // 通过麦克风采集的音频创建一个音频轨道。
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      // 通过摄像头采集的视频创建一个视频轨道。
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
      // 发布本地音视频轨道
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack])
      // 视频轨道play
      rtc.localVideoTrack.play(localPlayerContainer)
    },
    // 关闭视频
    handleClose(done) {
      this.$confirm('确认关闭视频？')
        .then(async (_) => {
          rtc.localAudioTrack.close()
          rtc.localVideoTrack.close()
          await rtc.client.leave()
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>

<style type="text/css" scoped lang="scss">
.box {
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.title {
  font-size: 30px;
  color: #333;
  font-weight: 700;
  padding: 15px 60px;
  border-bottom: 1px solid #4086fc;
}

.content {
  padding: 15px 70px;
}
.table {
  margin-top: 10px;
}
</style>
