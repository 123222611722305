import common from './common.js'
import user from './user/user.js'
import plan from './plan/plan.js'
import calendar from './calendar/calendar.js'
import consult from './consult/consult.js'
import mall from './mall/mall.js'

let api = {
	user: user,
	plan: plan,
	calendar: calendar,
	consult: consult,
	mall: mall,
	common: common
}

export default api
