import axios from '../utils/axios.js'

let common = {
  // 获取表单
  getFromField(data) {
    return axios({
      method: 'get',
      url: '/api/cases/getFromField',
      data: data,
    })
  },
  // 获取首页信息
  getIndexList(data) {
    return axios({
      method: 'get',
      url: '/api/index/getIndexList',
      data: data,
    })
  },
}

export default common
