// 测试
// const requestURL = 'https://bk.qsjykj.com.cn/'
// 正式
const requestURL = 'https://bkhx.bankmartech.com/interface/'

const uploadURL = requestURL + 'api/common/upload'
export default {
  requestURL: requestURL, // 测试版
  uploadURL: uploadURL, //测试上传地址
}
